import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";

function UserAddress({setIsZipCodeServicable, isZipCodeServicable}) {

  const [userInfo, setUserInfo] = useState({
    name: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    zip: ""
  });

  const [errors, setErrors] = useState({});
  const [zipCheckMessage, setZipCheckMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddressSaved, setIsAddressSaved] = useState(false);
 
  useEffect(() => {
    // Check if there's a saved address in sessionStorage on component load
    const savedAddress = sessionStorage.getItem("userAddress");
    if (savedAddress) {
      setUserInfo(JSON.parse(savedAddress));
      setIsAddressSaved(true);
      setIsEditing(false);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
    setIsAddressSaved(false); // Hide edit button on any input change
  };

  const validateInputs = () => {
    let tempErrors = {};
    if (!userInfo.name) tempErrors.name = "Name is required";
    if (!userInfo.phone) tempErrors.phone = "Phone number is required";
    else if (!/^\d{10}$/.test(userInfo.phone))
      tempErrors.phone = "Phone number is not valid";
    if (!userInfo.street) tempErrors.street = "Street address is required";
    if (!userInfo.city) tempErrors.city = "City is required";
    if (!userInfo.state) tempErrors.state = "State is required";
    if (!userInfo.zip) tempErrors.zip = "Zip code is required";
    else if (!/^\d{6}$/.test(userInfo.zip))
      tempErrors.zip = "Zip code is not valid";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleZipCodeCheck = async (zip) => {
    setLoading(true);
    try {
        const response = await axios.get(`https://track.delhivery.com/c/api/pin-codes/json/`, {
            params: {
                token: "454efe120c467091556c613bada8af5b3bab3bc5",  // Insert the token here
                filter_codes: zip
            },
            headers: {
                Accept: "application/json",
                Cookie:"sessionid=l78dei4ygp4dc26fou7roy2bfebodop8"
            }
        });
        
        const data = response.data;
        console.log('data response from the api is...', data)
        if (data.delivery_codes && data.delivery_codes[0]?.postal_code === zip) {
            setZipCheckMessage("This zip code is serviceable.");
            setIsZipCodeServicable(true);
        } else {
            setZipCheckMessage("This zip code is not serviceable.");
            setIsZipCodeServicable(false);
        }
    } catch (error) {
        setZipCheckMessage("Error checking zip code serviceability.");
        setIsZipCodeServicable(false);
    } finally {
        setLoading(false);
    }
};

  const handleZipCodeChange = (e) => {
    const zip = e.target.value;
    if (/^\d{0,6}$/.test(zip)) {
      setUserInfo((prevInfo) => ({ ...prevInfo, zip }));
      setZipCheckMessage("");
      setIsZipCodeServicable(false);

      // Call the API if the zip code reaches exactly 6 digits
      if (zip.length === 6) {
        
        handleZipCodeCheck(zip);
      }
    }
  };

  const handleSaveAddress = () => {
    if (validateInputs() && isZipCodeServicable) {
      sessionStorage.setItem("billUserAddress", JSON.stringify(userInfo));
      setIsAddressSaved(true);
      setIsEditing(false);
      alert("Address saved successfully!");
    }
  };

  const handleEditAddress = () => {
    setIsEditing(true);
    setIsAddressSaved(false);
  };

  return (
    <Box mt={4}>
      <TextField
        fullWidth
        label="Name"
        variant="outlined"
        name="name"
        value={userInfo.name}
        onChange={handleInputChange}
        error={!!errors.name}
        helperText={errors.name}
        sx={{ marginBottom: ".5rem" }}
        disabled={!isEditing && isAddressSaved}  // Disable if address is saved and not in edit mode
      />
      <TextField
        fullWidth
        label="Phone Number"
        variant="outlined"
        name="phone"
        value={userInfo.phone}
        onChange={handleInputChange}
        error={!!errors.phone}
        helperText={errors.phone}
        sx={{ marginBottom: ".5rem" }}
        disabled={!isEditing && isAddressSaved}
      />
      <TextField
        fullWidth
        label="Street Address"
        variant="outlined"
        name="street"
        value={userInfo.street}
        onChange={handleInputChange}
        error={!!errors.street}
        helperText={errors.street}
        sx={{ marginBottom: ".5rem" }}
        disabled={!isEditing && isAddressSaved}
      />
      <TextField
        fullWidth
        label="City"
        variant="outlined"
        name="city"
        value={userInfo.city}
        onChange={handleInputChange}
        error={!!errors.city}
        helperText={errors.city}
        sx={{ marginBottom: ".5rem" }}
        disabled={!isEditing && isAddressSaved}
      />
      <TextField
        fullWidth
        label="State"
        variant="outlined"
        name="state"
        value={userInfo.state}
        onChange={handleInputChange}
        error={!!errors.state}
        helperText={errors.state}
        sx={{ marginBottom: ".5rem" }}
        disabled={!isEditing && isAddressSaved}
      />
      <TextField
        fullWidth
        label="Zip Code"
        variant="outlined"
        name="zip"
        value={userInfo.zip}
        onChange={handleZipCodeChange}
        error={!!errors.zip}
        helperText={errors.zip || zipCheckMessage}
        sx={{ marginBottom: ".5rem" }}
        disabled={!isEditing && isAddressSaved}
      />
      {(!isAddressSaved || isEditing) && isZipCodeServicable && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveAddress}
          disabled={loading}
          sx={{ marginRight: "1rem" }}
        >
          {loading ? "Checking..." : "Save Address"}
        </Button>
      )}
      {isAddressSaved && !isEditing && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleEditAddress}
        >
          Edit Address
        </Button>
      )}
    </Box>
  );
}

export default UserAddress;
