import { centralizedApi } from "../services/API/centralizedApi";
import { toastError } from "./toastUtil";

export const handleGetData = async (apiItem) => {
    // Define a mapping of API items to functions
    const apiFunctions = {
      categories: centralizedApi.categories,
      subcategories: centralizedApi.subCategories,
      items: centralizedApi.items,
      orders:centralizedApi.orders,
      addAdvertisement: centralizedApi.addAdvertisement,
      changeWebInfo: centralizedApi.changeWebInfo
    };
  
    // Check if the apiItem exists in the mapping
    const apiFunction = apiFunctions[apiItem];
  
    if (!apiFunction) {
      console.error('Invalid API item:', apiItem);
      return;
    }
  
    try {
      // Call the function and get the response
      const response = await apiFunction();
      console.log(response);
      if (response) {
        // Handle the response data here
        return response
      }
    } catch (error) {
        toastError("Something went wrong.")
      console.error('Error fetching data:', error);
    }
  };

  export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };
  


  export const getCatDat = async ()=>{
    const response = await centralizedApi.categories();
    return response;
  }

  export const handleLogout = async(navigate)=>{
    sessionStorage.clear();
    localStorage.clear();
    navigate('/')
    window.location.reload();
  }