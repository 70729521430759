import toast from "react-hot-toast";

export const toastSuccess = (msg) => {
    toast.success(msg, {
        duration: 2000,
    });
};
export const toastError = (msg) => {
    toast.error(msg, {
        duration: 2000,
    });
};

export const toastWarning = (msg) => {
    toast(msg, {
        duration: 2000,
    });
};

export const toastInfo = (msg) => {
    toast(msg, {
        duration: 2000,
    });
};

export const toastLoading = (msg) => {
    toast.loading(msg, {
        duration: 2000,
    });
};