import { apiConfig } from "./apiConfig";
import axios from "axios";
import { getCookie } from "../../utils";

export const adminApi = {
  getWebInfo: async () => {
    const csrfToken = getCookie("adminToken");
    const response = await axios.get(apiConfig.baseUrl + apiConfig.webInfo);
    return response.data;
  },
  createWebInfo: async (data) => {
    const csrfToken = getCookie("adminToken");
    const response = await axios.post(
      apiConfig.baseUrl + apiConfig.webInfo,
      data
    );
    return response.data;
  },
  editWebInfo: async (id, data) => {
    const response = await axios.patch(
      apiConfig.baseUrl + apiConfig.webInfo + `${id}/`,
      data
    );
    return response?.data;
  },
  getAdvertiseInfo: async () => {
    const csrfToken = getCookie("adminToken");
    const response = await axios.get(apiConfig.baseUrl + apiConfig.advertise);
    return response.data;
  },
  postAdvertiseInfo: async (data) => {
    const csrfToken = getCookie("adminToken");
    const response = await axios.post(
      apiConfig.baseUrl + apiConfig.advertise,
      data
    );
    return response.data;
  },
};
