import React, { useState } from "react";
import { Box, Typography, IconButton, Grid, Divider, Modal, Fade } from "@mui/material";
import { Facebook, Twitter, Instagram, YouTube, Close } from "@mui/icons-material";
import { termsAndConditions, cancellationpolicy, privacyPolicy, shippingPolicy } from "../../../utils/policyUtils";

const UserFooter = () => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const handleOpenModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Box component="footer" sx={{ backgroundColor: "#1A1A1D", color: "white", py: 6 }}>
      <Grid container spacing={5} sx={{ px: 8 }}>
        {/* Customer Service Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 600, color: "#F0A500" }}>
            Customer Service
          </Typography>
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            <li>
              <a href="#" style={{ color: "#F5F5F5", textDecoration: "none" }}>
                Contact Us
              </a>
            </li>
            <li>
              <a href="#" style={{ color: "#F5F5F5", textDecoration: "none" }}>
                FAQs
              </a>
            </li>
            <li>
              <a href="#" style={{ color: "#F5F5F5", textDecoration: "none" }}>
                Returns
              </a>
            </li>
          </ul>
        </Grid>

        {/* Company Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 600, color: "#F0A500" }}>
            Company
          </Typography>
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            <li>
              <a href="#" style={{ color: "#F5F5F5", textDecoration: "none" }}>
                About Us
              </a>
            </li>
            <li>
              <a href="#" style={{ color: "#F5F5F5", textDecoration: "none" }}>
                Careers
              </a>
            </li>
            <li>
              <a href="#" style={{ color: "#F5F5F5", textDecoration: "none" }}>
                Press
              </a>
            </li>
          </ul>
        </Grid>

        {/* Company Policies Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 600, color: "#F0A500" }}>
            Company Policies
          </Typography>
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            <li>
              <a href="#" onClick={() => handleOpenModal("Terms and Conditions", termsAndConditions)} style={{ color: "#F5F5F5", textDecoration: "none" }}>
                Terms and Conditions
              </a>
            </li>
            <li>
              <a href="#" onClick={() => handleOpenModal("Shipping Policy", shippingPolicy)} style={{ color: "#F5F5F5", textDecoration: "none" }}>
                Shipping Policy
              </a>
            </li>
            <li>
              <a href="#" onClick={() => handleOpenModal("Privacy Policy", privacyPolicy)} style={{ color: "#F5F5F5", textDecoration: "none" }}>
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#" onClick={() => handleOpenModal("Cancellation Policy", cancellationpolicy)} style={{ color: "#F5F5F5", textDecoration: "none" }}>
                Cancellation Policy
              </a>
            </li>
          </ul>
        </Grid>

        {/* Social Media Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 600, color: "#F0A500" }}>
            Follow Us
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton color="inherit" href="#">
              <Facebook />
            </IconButton>
            <IconButton color="inherit" href="#">
              <Twitter />
            </IconButton>
            <IconButton color="inherit" href="#">
              <Instagram />
            </IconButton>
            <IconButton color="inherit" href="#">
              <YouTube />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: 4, backgroundColor: "#444" }} />
      {/* Footer Bottom */}
      <Typography variant="body2" sx={{ textAlign: "center", color: "#BBB" }}>
        © 2024 Quirckart. All Rights Reserved.
      </Typography>

      {/* Modal for Policy Content */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <Fade in={open}>
          <Box sx={{
            bgcolor: "#F9F3F0",
            color: "#333",
            p: 4,
            width: "50vw",
            maxHeight: "75vh",
            overflowY: "auto",
            mx: "auto",
            mt: 10,
            borderRadius: 2,
            boxShadow: 24,
            position: "relative"
          }}>
            {/* Close Button */}
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "#333",
              }}
            >
              <Close />
            </IconButton>

            {/* Modal Content */}
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: "#E07A5F" }}>
              {modalTitle}
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "1.6", color: "#555" }}>
              {modalContent}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default UserFooter;
