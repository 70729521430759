// import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
// import { useSelector } from 'react-redux';
// import './CategoryCarousel.css';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import Image from '../../GlobalComponents/Image';

// function ScrollCategories() {
//   const { categoryData } = useSelector((state) => state.userJourneyData);
//   // Force slider to reset on mount

//   // var settings = {
//   //   dots: true,
//   //   infinite: false,
//   //   speed: 500,
//   //   slidesToShow: 10,
//   //   slidesToScroll: 10,
//   //   initialSlide: 0,
//   //   responsive: [
//   //     {
//   //       breakpoint: 1024,
//   //       settings: {
//   //         slidesToShow: 6,
//   //         slidesToScroll: 6,
//   //         infinite: true,
//   //         dots: true
//   //       }
//   //     },
//   //     {
//   //       breakpoint: 600,
//   //       settings: {
//   //         slidesToShow: 4,
//   //         slidesToScroll: 4,
//   //         initialSlide: 4
//   //       }
//   //     },
//   //     {
//   //       breakpoint: 480,
//   //       settings: {
//   //         slidesToShow: 4,
//   //         slidesToScroll: 4,
//   //         initialSlide: 4
//   //       }
//   //     }
//   //   ]
//   // };
//   var settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 10,
//     slidesToScroll: 10,
//     initialSlide: 0, // Set initialSlide to 0 for default setting
//     lazyLoad: "ondemand", // Adds lazy loading
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 6,
//           slidesToScroll: 6,
//           infinite: true,
//           dots: true,
//           initialSlide: 0, // Reset to 0 for this breakpoint
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 4,
//           slidesToScroll: 4,
//           initialSlide: 0, // Reset to 0 for this breakpoint
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 4,
//           slidesToScroll: 4,
//           initialSlide: 0, // Reset to 0 for this breakpoint
//         },
//       },
//     ],
//   };
  
//   return (
//     <div className="categories-carousel">
//       <Slider {...settings}>
//         {categoryData?.map((category) => (
//           <div key={category?.id} className="category-item cursor-pointer" >
//             <Link to={`/category/${category?.id}`} className="category-link">
//               <div className="category-image-wrapper" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
//                 <Image src={category?.image} styles={{width: "100%", height: "100%", objectFit: "cover"}} alt={category?.name}/>
//                 {/* <img src={category?.image} alt={category?.name} className="category-image" /> */}
//               </div>
//               <p className="category-name">{category?.name}</p>
//             </Link>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// }

// export default ScrollCategories;


import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import './CategoryCarousel.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Image from '../../GlobalComponents/Image';

function ScrollCategories() {
  const { categoryData } = useSelector((state) => state.userJourneyData);
  const [sliderKey, setSliderKey] = useState(0);

  // Force slider to reset on mount
  useEffect(() => {
    setSliderKey(prevKey => prevKey + 1);
  }, [categoryData]);

  var settings = { 
    dots: true,
    infinite: false, 
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 10,
    initialSlide: 0,
    lazyLoad: "ondemand", // Adds lazy loading
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <div className="categories-carousel">
      <Slider key={sliderKey} {...settings}>
        {categoryData?.map((category) => (
          <div key={category?.id} className="category-item cursor-pointer">
            <Link to={`/category/${category?.id}`} className="category-link">
              <div className="category-image-wrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Image src={category?.image} styles={{ width: "100%", height: "100%", objectFit: "cover" }} alt={category?.name} />
              </div>
              <p className="category-name">{category?.name}</p>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ScrollCategories;
