import React from "react";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import CategoryIcon from "@mui/icons-material/Category";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import WidgetsIcon from "@mui/icons-material/Widgets";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import InfoIcon from "@mui/icons-material/Info";
import { Box } from "@mui/material";

const ActiveButton = styled(Button)(({ theme, active }) => ({
  width: "100%",
  justifyContent: "flex-start",
  backgroundColor: active ? theme.palette.primary.main : "transparent",
  color: active
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
  "&:hover": {
    backgroundColor: active
      ? theme.palette.primary.dark
      : theme.palette.action.hover,
  },
}));

const ListItemButton = ({ text, isActive, onClick }) => {
  const Icon =
    text === "Category"
      ? CategoryIcon
      : text === "Sub-Category"
      ? AccountTreeIcon
      : text === "Product"
      ? WidgetsIcon
      : text === "Orders"
      ? LocalShippingIcon
      : text === "Add Advertiesment"
      ? AddPhotoAlternateIcon
      : InfoIcon;
  return (
    <ListItem>
      <ActiveButton
        style={{ padding: "1rem" }}
        active={isActive ? 1 : 0}
        onClick={onClick}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>{text}</Box>
          <Box>{Icon && <Icon />}</Box>
        </Box>
      </ActiveButton>
    </ListItem>
  );
};

export default ListItemButton;
