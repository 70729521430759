import { Button } from "@mui/material";
import React from "react";

function AddContainer({
  page,
  handleAddCategoryToggle,
  handleAddSubCategoryToggle,
  handleAddProductToggle,
}) {
  console.log("page inside add conatiner", page)
  let buttonName =
    page === "Category"
      ? "Add Category"
      : page === "Sub-Category"
      ? "Add Sub-Category"
      : page === "Product"
      ? "Add Product"
      : "";
  let dialogOpenFunc =
    page === "Category"
      ? handleAddCategoryToggle
      : page === "Sub-Category"
      ? handleAddSubCategoryToggle
      : page === "Product"
      ? handleAddProductToggle
      : "";

  return (
    <div className="w-full flex justify-end px-4">
      {
        buttonName && <Button variant="contained" onClick={dialogOpenFunc}>
        {buttonName}
      </Button>
      }
    </div>
  );
}

export default AddContainer;
