import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Divider, Collapse, Chip } from '@mui/material';
import { styled } from '@mui/system';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const OrderBox = styled(Box)({
  marginBottom: '1rem',
  border: '1px solid #e0e0e0',
  borderRadius: '0.5rem',
  padding: '1rem',
});

const ProductBox = styled(Box)({
  marginTop: '1rem',
  border: '1px solid #f0f0f0',
  borderRadius: '0.5rem',
  padding: '1rem',
  display: 'flex',
  alignItems: 'center',
});

const ProductImage = styled('img')({
  width: '80px',
  height: '80px',
  marginRight: '1rem',
  borderRadius: '0.5rem',
  objectFit: 'cover',
});

const UserOrders = () => {
  const [expandedOrders, setExpandedOrders] = useState([]);
  const orderDetails = JSON.parse(sessionStorage.getItem("userDetailsFetch"))
  const orders = [
    {
      id: 1,
      date: '2024-09-02',
      status: 'Delivered',
      products: [
        {
          id: 1,
          name: 'Product A',
          price: 1000,
          quantity: 1,
          image: 'https://via.placeholder.com/100', // Replace with actual image URL
        },
        {
          id: 2,
          name: 'Product B',
          price: 2000,
          quantity: 2,
          image: 'https://via.placeholder.com/100', // Replace with actual image URL
        },
      ],
      totalAmount: 5000,
    },
    {
      id: 2,
      date: '2024-09-01',
      status: 'Shipped',
      products: [
        {
          id: 3,
          name: 'Product C',
          price: 1500,
          quantity: 1,
          image: 'https://via.placeholder.com/100', // Replace with actual image URL
        },
      ],
      totalAmount: 1500,
    },
  ];

  const toggleOrderDetails = (orderId) => {
    setExpandedOrders((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const getStatusChipColor = (status) => {
    switch (status) {
      case 'Delivered':
        return 'success'; // Green color
      case 'Shipped':
        return 'primary'; // Blue color
      case 'Cancelled':
        return 'error'; // Red color
      default:
        return 'default'; // Grey color for unknown status
    }
  };

  return (
    <Box className="p-4">
      <Typography variant="h4" className="mb-4">
        My Orders
      </Typography>
      {
        orderDetails?.orders?.length>0 ?
      <>
      {orderDetails?.orders?.map((order) => (
        <OrderBox key={order.id}>
          <Box className="flex justify-between items-center mb-2">
            <Typography variant="h6">Order ID: {order.id}</Typography>
            <Button
              endIcon={expandedOrders.includes(order.id) ? <ExpandLess /> : <ExpandMore />}
              onClick={() => toggleOrderDetails(order.id)}
              className="text-blue-500"
            >
              {expandedOrders.includes(order.id) ? 'Hide Details' : 'Show Details'}
            </Button>
          </Box>
          <Divider />
          <Box className="flex justify-between items-center mt-2">
            <Typography>Date: {order.date}</Typography>
            <Chip label={order.status} color={getStatusChipColor(order.status)} />
          </Box>
          <Typography>Total Amount: ₹{order.totalAmount}</Typography>
          <Collapse in={expandedOrders.includes(order.id)} timeout="auto" unmountOnExit>
            <Box className="mt-2">
              {order?.products?.map((product) => (
                <ProductBox key={product?.id}>
                  <ProductImage src={product?.image} alt={product?.name} />
                  <Box>
                    <Typography variant="body1">{product?.name}</Typography>
                    <Typography>Price: ₹{product?.price}</Typography>
                    <Typography>Quantity: {product?.quantity}</Typography>
                  </Box>
                </ProductBox>
              ))}
            </Box>
          </Collapse>
        </OrderBox>
      ))}
      </>:<div className='mt-16'><h2>No Orders Yet!!!</h2><Button variant='contained'>Order Something</Button></div>
      }
    </Box>
  );
};

export default UserOrders;
