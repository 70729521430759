import React, { useEffect, useState } from "react";
import AddContainer from "../../AddContainer";
import { useOutletContext } from "react-router-dom";
import { Button, Dialog } from "@mui/material";
import AddCategory from "../../AddContainer/AddCategory";
import AdminContent from "../../AdminContent";
import { handleGetData } from "../../../../utils";
import CategoryContent from "../../AdminContent/CategoryContent";
import { useDispatch, useSelector } from "react-redux";
import { setCategories } from "../../../../store/slices/categorySlice";
function Categories() {
  const { page, tableData, setTableData } = useOutletContext();
  const [pageTableData, setPageTableData] = useState([])
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const dispatch = useDispatch();
  
  // console.log("categories from store", categories);
  const handleAddCategoryToggle = () => {
    setAddCategoryOpen((prev) => !prev);
  };
  async function callgetData(){
      const categoryData = await handleGetData('categories')
      setPageTableData(categoryData)
      dispatch(setCategories(categoryData))
  }


  useEffect(()=>{

    callgetData()

  },[])

    // Trigger data fetch when the dialog closes
    useEffect(() => {
      if (!addCategoryOpen) {
        callgetData();
      }
    }, [addCategoryOpen]);

  return (
    <div>
      <div className="w-full flex justify-end px-4">
        <Button onClick={handleAddCategoryToggle} variant="contained">
          Add Category
        </Button>
      </div>
      <CategoryContent page={page} tableData={pageTableData} callgetData={callgetData} setTableData={setTableData}/>
      {/* **********Add Category Dialog*********** */}
      <Dialog open={addCategoryOpen} onClose={handleAddCategoryToggle}>
        <AddCategory handleAddCategoryToggle={handleAddCategoryToggle} setTableData={setTableData}/>
      </Dialog>
    </div>
  );
}

export default Categories;
