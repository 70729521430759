import React, { useEffect, useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  ImageWithZoom,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  IconButton,
} from "@mui/material";
import { Add, Remove, ArrowBack, ArrowForward } from "@mui/icons-material";
import Rating from "@mui/material/Rating";
import "./AffiliateProduct.css";
import Image from "../../GlobalComponents/Image";
import { useParams } from "react-router-dom";
import axios from "axios";
import { handleAddToCart } from "../../../utils/userUtils";
import { useDispatch, useSelector } from "react-redux";
import Affiliate from "..";

function AffiliateProduct() {
  const [productData, setProductData] = useState(null); // Set initial state to null
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1); // For zoom management
  const { productId } = useParams();
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.userDetailsData);

  useEffect(() => {
    async function fetchSingleProduct() {
      try {
        const response = await axios.get(
          `http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/api/items/${productId}`
        );
        console.log("product idata is", response.data);
        setProductData(response.data);
      } catch (error) {
        console.log("Error fetching product data", error);
      }
    }
    fetchSingleProduct();
  }, [productId]);

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };

  const handleQuantityChange = (amount) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  const handleImageZoom = () => {
    setZoomLevel((prevZoomLevel) => (prevZoomLevel === 1 ? 2 : 1)); // Toggle zoom level
  };

  if (!productData) {
    return <div>Loading product data...</div>; // Loading state
  }

  const discountedPrice = productData.discount
    ? (
        productData.price -
        productData.price * (productData.discount / 100)
      ).toFixed(2)
    : null;

  return (
    <div className="p-4 grid grid-cols-1 md:grid-cols-5 gap-4">
      {/* Adjust column size */}
      <div className="md:col-span-2 relative">
        <CarouselProvider
          naturalSlideWidth={200}
          naturalSlideHeight={180}
          totalSlides={productData.images.length} // Ensure images exist in productData
          visibleSlides={1}
          currentSlide={activeIndex}
          className="shadow-md h-full"
        >
          <Slider className="h-full">
            {productData.images.map((image, index) => (
              <Slide key={index} index={index}>
                <div
                  className={`carousel-image-wrapper zoom-${zoomLevel}`}
                  onClick={handleImageZoom}
                >
                  <ImageWithZoom src={image} className="carousel-image"/>
                </div>
              </Slide>
            ))}
          </Slider>
          <ButtonBack className="absolute top-1/2 left-2 transform -translate-y-1/2 p-1 bg-white shadow-md rounded-full">
            <ArrowBack />
          </ButtonBack>
          <ButtonNext className="absolute top-1/2 right-2 transform -translate-y-1/2 p-1 bg-white shadow-md rounded-full">
            <ArrowForward />
          </ButtonNext>
        </CarouselProvider>
        {/* Thumbnail Row */}
        <div className="absolute bottom-2 left-0 right-0 flex justify-center gap-2">
          {productData.images.map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`Thumbnail ${index}`}
              className={`thumbnail ${
                index === activeIndex ? "active" : "inactive"
              }`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      </div>

      <div className="p-4 md:col-span-2">
        <div className="flex justify-between">
          <Typography variant="h5" align="left">
            {productData.name}
          </Typography>
          {/* Stock Availability */}
          <Typography
            variant="subtitle1"
            align="right"
            color={productData?.stock > 0 ? "green" : "red"}
          >
            {productData?.stock > 0
              ? `In Stock: ${productData?.stock}`
              : "Out of Stock"}
          </Typography>
        </div>
        <Box sx={{ textAlign: "left" }}>
          <Rating 
          // value={productData.rating} 
          value={4.5} 
          readOnly precision={0.5} />
        </Box>
        <Typography variant="body1" align="left">
          {productData?.specification}
        </Typography>

        {/* Pricing Section */}
        <Typography variant="h6" className="text-red-500" align="left">
          {discountedPrice ? (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹{productData.price}
              </span>{" "}
              <span style={{ color: "green" }}>₹{discountedPrice}</span>
            </>
          ) : (
            `₹${productData?.price}`
          )}
        </Typography>
        {productData?.discount && (
          <Typography variant="subtitle1" color="green" align="left">
            {productData?.discount}% off
          </Typography>
        )}

        {/* Color Selection */}
        {/* <div className="mt-4" align="left">
          <Typography variant="subtitle1">Color</Typography>
          <RadioGroup value={selectedColor} onChange={handleColorChange} row>
            {productData.colors?.map((color) => (
              <FormControlLabel key={color} value={color} control={<Radio />} label={color} />
            ))}
          </RadioGroup>
        </div> */}

        {/* Size Selection */}
        {/* <div className="mt-4" align="left">
          <Typography variant="subtitle1">Size</Typography>
          <Box display="flex" gap={1}>
            {productData.sizes?.map((size) => (
              <Button
                key={size}
                variant={selectedSize === size ? "contained" : "outlined"}
                onClick={() => handleSizeChange(size)}
              >
                {size}
              </Button>
            ))}
          </Box>
        </div> */}

        {/* Quantity Selection */}
        <div className="mt-4 flex items-center" align="left">
          <Typography variant="subtitle1" className="mr-4" style={{marginBottom:'.5rem'}}>
            Quantity
          </Typography>
          <div
            className="flex items-center ml-2 mb-2"
            style={{
              boxShadow: "0 0 2px 2px lightgray",
              borderRadius: ".2rem",
            }}
          >
            <IconButton onClick={() => handleQuantityChange(-1)}>
              <Remove />
            </IconButton>
            <Typography>{quantity}</Typography>
            <IconButton onClick={() => handleQuantityChange(1)}>
              <Add />
            </IconButton>
          </div>
        </div>

        {/* Add to Cart Button */}
        <Button
          onClick={() => handleAddToCart(productData, dispatch, quantity, cartItems)}
          variant="contained"
          color="primary"
          className="w-full"
          style={{ marginTop: "1rem" }}
          disabled={productData.stock <= 0} // Disable button if out of stock
        >
          {productData.stock > 0 ? "Add to Cart" : "Out of Stock"}
        </Button>
      </div>
    </div>
  );
}

export default AffiliateProduct;
