export const apiConfig = {
    baseUrl: "http://ecom2-env.eba-gmk4bctt.eu-north-1.elasticbeanstalk.com/api/",

    auth:"check-authenticated/",

    categories: "categories/",
    subcategories: "subcategories/",
    items: "items/",
    affiliateLinks: "affiliate-links/",
    orders: "orders/",  
    
    // User
    userCategory:"",
    userSubCategory:"",
    userProduct:"",
    userAdv:"",

    webInfo:"",
    advertise:"",
}