import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { Add, Remove, Delete } from "@mui/icons-material";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseCartItemQuantity,
  increaseCartItemQuantity,
  removeCartItem,
} from "../../../../utils/userUtils";
import Image from "../../../GlobalComponents/Image";

const UserCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = Cookies.get("userLogin"); // Check if user is logged in
  const { cartItems } = useSelector((state) => state.userDetailsData);
  console.log('cartitems are',cartItems)
  const handleCheckout = () => {
    navigate("/checkout"); // Navigates to the checkout page
  };

  // Calculate total price
  // const totalPrice = cartItems.reduce(
  //   (total, item) => total + item.price * item.quantity,
  //   0
  // );
  const totalPrice = cartItems.reduce((total, item) => {
    const itemPrice = item.discount
      ? item.price - (item.price * item.discount) / 100
      : item.price;

    return total + itemPrice * item?.quantity;
  }, 0);

  // Uncomment this block if you want to redirect to login if not logged in
  // if (!userLogin) {
  //   // If user is not logged in
  //   return (
  //     <Box className="flex flex-col items-center justify-center h-screen p-4">
  //       <Typography variant="h5" className="mb-4 text-center">
  //         Please log in to view your cart
  //       </Typography>
  //       <Button variant="contained" color="primary" onClick={handleLoginRedirect}>
  //         Login
  //       </Button>
  //     </Box>
  //   );
  // }
  return (
    <Box className="p-2 md:p-8">
      <Typography variant="h6" className="mb-4 text-left">
        My Cart ({cartItems?.length} items)
      </Typography>

      {/* Cart Items */}
      {cartItems?.map(
        (item) => (
          console.log("item in cart", item),
          (
            <Box
              key={item.id}
              className="flex flex-col md:flex-row items-start md:items-center justify-between border-b border-gray-300 py-4"
            >
              {/* Left Part - Product Image and Details */}
              <Box className="flex items-start md:flex-row md:items-center w-full md:w-3/5">
                {/* Product Image */}
                <Box
                  className="w-24 h-24 flex-shrink-0 items-center"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {/* <img
                src={item?.images?.[0]}
                alt={item?.name}
                className="object-cover w-full h-full rounded-md"
              /> */}
                  <Image
                    src={item?.images?.[0]}
                    alt={item?.name}
                    classname={" w-full h-full rounded-md"}
                    styles={{ objectFit: "contain" }}
                  />
                </Box>

                {/* Product Details */}
                <Box className="ml-4 flex flex-col text-left">
                  <Typography variant="h6">{item.name}</Typography>
                  {item?.discount ? (
                    <Typography>
                      <span
                        style={{ textDecorationLine: "line-through" }}
                        className="text-hightlightRed"
                      >
                        ₹ {item.price}
                      </span>{" "}
                      <span className="pl-2 text-xl text-hightlightGreen font-bold">
                        ₹
                        {(
                          item.price -
                          (item.price * item.discount) / 100
                        ).toFixed(2)}
                      </span>
                    </Typography>
                  ) : (
                    <Typography>₹{item.price}</Typography>
                  )}
                  {item?.discount && (
                    <Typography className="text-sm  mt-1 text-highlightGreen">
                      {item?.discount} % off
                    </Typography>
                  )}
                  <Typography className="text-sm text-gray-400 mt-1">
                    {item?.specification}
                  </Typography>
                </Box>
              </Box>

              {/* Right Part - Quantity Controls and Remove Button */}
              <Box className="flex flex-col  items-end md:items-end w-full md:w-2/5 mt-4 md:mt-0">
                {/* Total Price for Item */}
                <Typography className="text-gray-600 mb-4 font-semibold"style={{marginBottom:'.5rem'}}>
                  {/* ₹{(item.price * item.quantity).toFixed(2)} */}
                  <Typography className="text-gray-600 mt-2 font-semibold" style={{fontWeight:'bold'}}>
                    ₹
                    {item.discount
                      ? (
                          (item.price - (item.price * item.discount) / 100) *
                          item?.quantity
                        ).toFixed(2)
                      : (item.price * item?.quantity).toFixed(2)}
                  </Typography>
                </Typography>
                {/* Quantity Controls */}
                <Box
                  className="flex items-center mb-2"
                  style={{
                    boxShadow: "0 0 10px 1px lightgray",
                    borderRadius: ".5rem",
                  }}
                >
                  <IconButton
                    onClick={() => decreaseCartItemQuantity(item.id, dispatch)}
                    aria-label="Decrease quantity"
                  >
                    <Remove />
                  </IconButton>
                  <Typography className="mx-2 px-3">{item?.quantity}</Typography>
                  <IconButton
                    onClick={() => increaseCartItemQuantity(item.id, dispatch)}
                    aria-label="Increase quantity"
                  >
                    <Add />
                  </IconButton>
                </Box>

                {/* Remove Item Button */}
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<Delete />}
                  onClick={() => removeCartItem(item.id, dispatch)}
                  style={{ marginTop: ".5rem" }}
                >
                  Remove
                </Button>
              </Box>
            </Box>
          )
        )
      )}

      {cartItems?.length > 0 ? (
        <>
          <Box className="flex justify-end mt-6">
            <Typography variant="h6" className="font-semibold">
              Total: ₹{totalPrice?.toFixed(2)}
            </Typography>
          </Box>

          <Box className="mt-4 text-center">
            <Button
              onClick={handleCheckout}
              variant="contained"
              color="primary"
              className="w-full md:w-auto bg-hightlightRed"
            >
              Proceed to Checkout
            </Button>
          </Box>
        </>
      ) : (
        <Box
          className="mt-4 text-center flex flex-col justify-center items-center"
          style={{ height: "400px" }}
        >
          <Typography variant="h5" style={{ marginBottom: "1rem" }}>
            Oops Nothing in Cart
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className="w-full md:w-auto bg-hightlightRed"
            onClick={() => {
              navigate("/");
            }}
          >
            Go Back to Shop
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UserCart;
