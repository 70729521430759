import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './ImageSection.css';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const ImageSection = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const windowSize = useWindowSize();
  const imagesPerView = windowSize < 768 ? 1 : 3;

  useEffect(() => {
    const staticImages = [
      { id: 1, src: 'https://testingbucketboto3.s3.amazonaws.com/1.png', alt: 'Image 1', },
      { id: 2, src: 'https://testingbucketboto3.s3.amazonaws.com/2.png', alt: 'Image 2', },
      { id: 3, src: 'https://testingbucketboto3.s3.amazonaws.com/3.png', alt: 'Image 3' },
      { id: 4, src: 'https://testingbucketboto3.s3.amazonaws.com/4.png', alt: 'Image 4' },
      { id: 5, src: 'https://testingbucketboto3.s3.amazonaws.com/5.png', alt: 'Image 5' },
    ];
    setImages(staticImages);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= images.length - imagesPerView ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - imagesPerView : prevIndex - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  // Set up auto-swipe with a looping interval
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000); // Auto-swipe every 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [images, currentIndex, imagesPerView]);

  return (
    <div className="image-section">
      <button onClick={handlePrev} className="nav-button">‹</button>

      <div className="image-container" {...handlers}>
        {images.slice(currentIndex, currentIndex + imagesPerView).map((image) => (
          <div key={image.id} className="image-box">
            <img src={image.src} alt={image.alt} className="image" />
          </div>
        ))}
      </div>

      <button onClick={handleNext} className="nav-button">›</button>
    </div>
  );
};

export default ImageSection;
