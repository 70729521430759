
// import React, { useEffect } from 'react';
// import { useForm, Controller } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
// import {
//   Button,
//   TextField,
//   Typography,
//   DialogActions,
//   DialogContent,
//   DialogTitle
// } from '@mui/material';
// import axios from 'axios';
// import { toastError, toastSuccess } from '../../../utils/toastUtil';
// import { adminApi } from '../../../services/API/admin';

// const webInfoSchema = yup.object().shape({
//   mobileNumber: yup.string()
//     .required('Mobile number is required')
//     .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits'),
  
//   email: yup.string()
//     .required('Email is required')
//     .email('Invalid email format'),

//   address: yup.string()
//     .required('Address is required')
// });

// function AddWebInfo() {
//   const { handleSubmit, control, register, setValue, formState: { errors } } = useForm({
//     resolver: yupResolver(webInfoSchema)
//   });

//   useEffect(() => {
//     const fetchWebInfo = async () => {
//       try {
//         const response = await adminApi.getWebInfo()
//         // const data = response?.data;
//         // Autofill the form fields
//         if(response?.status==="success"){
//           let data = response.data;
//           setValue('mobileNumber', data?.mobileNumber || '');
//           setValue('email', data?.email || '');
//           setValue('address', data?.address || '');
//         }
//       } catch (error) {
//         toastError('Failed to fetch web info');
//       }
//     };

//     fetchWebInfo();
//   }, [setValue]);

//   const onSubmit = async (data) => {
//     try {
//       const response = await adminApi.getWebInfo()
//       if(response.status ==="success"){

//         toastSuccess('Web info updated successfully');
//       }
//     } catch (error) {
//       toastError('Failed to update web info');
//     }
//   };

//   return (
//     <>
//       <DialogTitle>Add Web Info</DialogTitle>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <DialogContent
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             width: '100%',
//           }}
//         >
//           <TextField
//             sx={{ width: '100%', marginTop: '.5rem' }}
//             placeholder="Mobile Number"
//             {...register('mobileNumber')}
//             error={!!errors.mobileNumber}
//             helperText={errors.mobileNumber?.message}
//           />
//           <TextField
//             sx={{ width: '100%', marginTop: '.5rem' }}
//             placeholder="Email"
//             {...register('email')}
//             error={!!errors.email}
//             helperText={errors.email?.message}
//           />
//           <TextField
//             sx={{ width: '100%', marginTop: '.5rem' }}
//             placeholder="Address"
//             {...register('address')}
//             error={!!errors.address}
//             helperText={errors.address?.message}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button type="submit" variant="contained">
//             Submit
//           </Button>
//         </DialogActions>
//       </form>
//     </>
//   );
// }

// export default AddWebInfo;


import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import axios from 'axios';
import { toastError, toastSuccess } from '../../../utils/toastUtil';
import { adminApi } from '../../../services/API/admin';

function AddWebInfo() {
  const { handleSubmit, register, setValue } = useForm();

  useEffect(() => {
    const fetchWebInfo = async () => {
      try {
        const response = await adminApi.getWebInfo();
        if(response?.status === "success") {
          let data = response.data;
          setValue('mobileNumber', data?.mobileNumber || '');
          setValue('email', data?.email || '');
          setValue('address', data?.address || '');
        }
      } catch (error) {
        toastError('Failed to fetch web info');
      }
    };

    fetchWebInfo();
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const response = await adminApi.editWebInfo(data);
      if(response.status === "success") {
        toastSuccess('Web info updated successfully');
      }
    } catch (error) {
      toastError('Failed to update web info');
    }
  };

  return (
    <>
      <DialogTitle>Add Web Info</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <TextField
            sx={{ width: '100%', marginTop: '.5rem' }}
            placeholder="Mobile Number"
            {...register('mobileNumber')}
          />
          <TextField
            sx={{ width: '100%', marginTop: '.5rem' }}
            placeholder="Email"
            {...register('email')}
          />
          <TextField
            sx={{ width: '100%', marginTop: '.5rem' }}
            placeholder="Address"
            {...register('address')}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </form>
    </>
  );
}

export default AddWebInfo;
