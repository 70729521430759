import React from "react";
import ProductList from "../UserPages/UserProduct/ProductList";
import ScrollCategories from "../ScrollCategories";
import ScrollHomeAdv from "../ScrollHomeAdv";
import HomeCategories from "../HomeCategories";
import TrendingProducts from "../TrendingProducts";
import { useSelector } from "react-redux";
import ScrollingBanner from "../../GlobalComponents/ScrollingBanner";
import ImageSection from "../../../Gallery/ImageSection";
import ProductComponent from "../../../Gallery/ProductComponent";
import VideoGallery from "../../../Gallery/VideoGallery";
import CollectionCard from "../../../Gallery/CollectionCard";

function UserHomeContainer() {
  const insightsData = useSelector(
    (state) => state?.userJourneyData?.insightsData
  );
  const {advData} = useSelector(state=>state.userJourneyData)
  console.log("insightsData", insightsData);

  return (
    <div>
      <ScrollingBanner />
    
      
      <ScrollHomeAdv />
      <ScrollCategories />
      <ImageSection />
      <VideoGallery />
      <TrendingProducts
        Text={"Top Selling Products"}
        insightsData={insightsData?.top_selling_products}
      />
      <TrendingProducts
        Text={"Handpicked Items"}
        insightsData={advData?.[0]?.handpicked_items}
      />
      <ProductComponent />
      <CollectionCard />
    </div>
  );
}

export default UserHomeContainer;
