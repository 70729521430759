import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Drawer } from "@mui/material";
import DrawerNav from "../../DrawerNav";
import AdminNav from "../AdminNav";

const AdminLayout = () => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState("Category");
  const [tableData, setTableData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  console.log("page", page)


  const handleDrawerOpen = () => setOpen((prev) => !prev);
  
  return (
    <>
      <AdminNav handleDrawerOpen={handleDrawerOpen} />
      <Drawer open={open} onClose={handleDrawerOpen}>
        <DrawerNav
          setPage={setPage}
          handleDrawerOpen={handleDrawerOpen}
          tableData={tableData}
          setTableData={setTableData}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </Drawer>
      <Outlet  context={{ page, tableData, setTableData }} />
      {/* <Outlet context={{ page, tableData, setTableData }} /> */}
    </>
  );
};

export default AdminLayout;
