import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  subcategories: [],
  products: [],
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSubcategories: (state, action) => {
      state.subcategories = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    clearAll: (state) => {
      state.categories = [];
      state.subcategories = [];
      state.products = [];
    },
  },
});

export const { setCategories, setSubcategories, setProducts, clearAll } = categorySlice.actions;

export default categorySlice.reducer;
