import "../../../index.css";
import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Drawer,
  Collapse,
  ListItemIcon,
  Typography,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Home,
  AccountCircle,
  ShoppingCart,
  Assignment,
  Login,
  Settings,
  HelpOutline,
  Logout,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../../index.css";
import { setCatAndSubData } from "../../../store/slices/UserJourneyDataSlice";
import axios from "axios";
import './UserDrawer.css'; // New CSS for modern look
import { handleLogout } from "../../../utils";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
function UserDrawer({ open, toggleDrawer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { catAndSubData } = useSelector((state) => state.userJourneyData);
  const [openCategory, setOpenCategory] = useState({});
  const userAccessToken = sessionStorage.getItem("userAccessToken")
  const affiliateAccess = sessionStorage.getItem("affiliateAccess")
  console.log("user acccesstoken in  userdrawer", userAccessToken)
  useEffect(() => {
    const getCatAndSubData = async () => {
      try {
        const response = await axios.get(
          "http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/api/list/"
        );
        dispatch(setCatAndSubData(response?.data));
      } catch (error) {
        console.log(
          "error while getting user category data",
          error?.response?.message
        );
      }
    };
    getCatAndSubData();
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
    toggleDrawer(false)(); // Close the drawer after navigation
  };

  const handleCategoryClick = (categoryName) => {
    setOpenCategory((prevState) => ({
      ...prevState,
      [categoryName]: !prevState[categoryName],
    }));
  };

  const list = (userAccessToken) => (

    <Box
      sx={{ width: 250 }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
      className="custom-scrollbar modern-drawer"  // New class for the modern drawer look
    >
      <img src="quirckart.png" alt="QuircKart" className="modern-logo" />
      <List className="modern-list">
        {[
          { text: "Home", icon: <Home className="modern-icon" />, path: "/" },
          userAccessToken && {
            text: "My Cart",
            icon: <ShoppingCart className="modern-icon" />,
            path: "/cart",
          },
          userAccessToken && {
            text: "My Orders",
            icon: <Assignment className="modern-icon" />,
            path: "/orders",
          },
          {
            text: "Affiliate Registration",
            icon: <Assignment className="modern-icon" />,
            path: "/affiliate",
          },
          affiliateAccess && {
            text: "Affliate Login",
            icon: <RecordVoiceOverIcon sx={{ fontSize: "20px" }} />,
            path: "/affiliate",
          },
        ]
          .filter(Boolean) // Filters out false or undefined items
          .map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => handleNavigation(item.path)}
              className="modern-list-item"
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <Typography className="modern-list-text">{item.text}</Typography>
            </ListItem>
          ))}
      </List>


      <Divider />

      <List className="modern-list">
        <ListItem>
          <Typography className="modern-category-title">Shop By Category</Typography>
        </ListItem>
        {catAndSubData?.map((category) => (
          <React.Fragment key={category?.name}>
            <ListItem
              button
              onClick={() =>
                category?.subcategories?.length > 0 &&
                handleCategoryClick(category.name)
              }
              className={`modern-category-item ${openCategory[category?.name] ? "active" : ""}`}
            >
              <Typography className={`modern-category-name ${openCategory[category?.name] ? "active" : ""}`}>
                {category?.name}
              </Typography>

              {category?.subcategories?.length > 0 && (
                <ListItemIcon className="modern-icon">
                  {openCategory[category?.name] ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
              )}
            </ListItem>

            {category?.subcategories?.length > 0 && (
              <Collapse in={openCategory[category.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {category.subcategories.map((subcategory) => (
                    <ListItem
                      button
                      key={subcategory.name}
                      onClick={() =>
                        handleNavigation(`category/${category?.id}/subcategory/${subcategory?.id}`)
                      }
                      className="modern-subcategory-item"
                    >
                      <Typography className="modern-subcategory-text">{subcategory?.name}</Typography>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>

      <Divider />

      <List className="modern-list">
        {[
          userAccessToken
            ? { text: "Logout", icon: <Logout className="modern-icon" />, path: "/logout" }
            : { text: "Login", icon: <Login className="modern-icon" />, path: "/login" },
        ].map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => { if (item.path === "/logout") { handleLogout(navigate) } else { handleNavigation(item.path) }; }}
            className="modern-list-item"
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <Typography className="modern-list-text">{item.text}</Typography>
          </ListItem>
        ))}
      </List>

    </Box>
  );

  return (
    <Drawer anchor="left" open={open} onClose={toggleDrawer(false)} className="modern-drawer-container">
      {list(userAccessToken)}
    </Drawer>
  );
}

export default UserDrawer;
