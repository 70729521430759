import "./App.css";
import Routes from "./router";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
// import store from "./store";
import store from "./store/store";
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Toaster />
        <Routes />
      </Provider>
    </div>
  );
}

export default App;
