import React, { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Chip,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../../utils/toastUtil";
import { centralizedApi } from "../../../services/API/centralizedApi";
import { productSchema } from "../../../utils/adminUtils";

function AddProduct({
  label,
  setEditProducts,
  handleClose,
  data,
  handleAddProductToggle,
  subcategories,
}) {
  const [tags, setTags] = useState([]);
  const [videoPreview, setVideoPreview] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [variants, setVariants] = useState([
    { color: "", size: "", price: 0, stock: 0 },
  ]);
  console.log("tags in product are ", tags)
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(productSchema),
    defaultValues: {
      subCategory: null,
      images: [],
      video: null,
    },
  });

  const filteredSubcategories = subcategories?.map((subcat) => ({
    value: subcat.id,
    label: subcat.name,
  }));

  useEffect(() => {
    if (data) {
      reset({
        productName: data?.name || "",
        itemsInStock: data?.stock || 0,
        productPrice: data?.price || 0,
        discount: data?.discount || 0,
        subCategory: subcategories.find(
          (subcat) => subcat.name === data?.subcategory
        )
          ? { value: data?.subcategory, label: data?.subcategory }
          : null,
        SKU: data?.sku || "",
        specification: data?.specification || "",
        specialItemStatus: data?.special_item_status || false,
        token: data?.token || 0,
        affiliateToken: data?.affiliate_token || 0,
        variantInput: data?.variantInput || "",
      });

      setVariants(
        data?.variants || [{ color: "", size: "", price: 0, stock: 0 }]
      );

      // Set image previews for autofill
      const initialImagePreviews = [];
      if (data?.image1) initialImagePreviews.push(data.image1);
      if (data?.image2) initialImagePreviews.push(data.image2);
      if (data?.image3) initialImagePreviews.push(data.image3);
      setImagePreviews(initialImagePreviews);

      setVideoPreview(data?.video ? data?.video : null);
    }
  }, [data, reset, subcategories]);

  const onSubmit = async (formData) => {
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.productName);
    formDataToSend.append("subcategory", formData.subCategory?.label || "");
    formDataToSend.append("sku", formData.SKU || "");
    formDataToSend.append("stock", formData.itemsInStock || 0);
    formDataToSend.append("price", formData.productPrice || 0);
    formDataToSend.append("discount", formData.discount || 0);
    formDataToSend.append("specification", formData.specification || "");
    formDataToSend.append("token", formData.token || 0);
    formDataToSend.append(
      "special_item_status",
      formData.specialItemStatus || false
    );
    formDataToSend.append("affiliate_token", formData.affiliateToken || "");

    // Handle keywords
    // formDataToSend.append("keywords_input", ["first", "second", "thrid", "fourth"]);
    formDataToSend.append("keywords_input", JSON.stringify(tags));

    
    // Handle variants
    // formDataToSend.append("variants_input", variants);
    formDataToSend.append("variants_input", JSON.stringify(variants));

    // Handle image uploads
    const images = watch("images");
    if (images && images.length > 0) {
      Array.from(images).forEach((file, index) => {
        formDataToSend.append(`image${index + 1}`, file);
      });
    }

    // Handle video upload
    const video = watch("video");
    if (video && video.length > 0) {
      formDataToSend.append("video", video[0]);
    }

    try {
      let response;
      if (label) {
        response = await centralizedApi.editItem(data?.id, formDataToSend);
      } else {
        response = await centralizedApi.createItem(formDataToSend);
      }
      if (response?.status === "success") {
        toastSuccess("Product Added Successfully.");
        handleAddProductToggle();
      }
    } catch (error) {
      toastError("Error while adding product.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.value) {
      event.preventDefault();
      const value = event.target.value.trim();
      if (value && !tags.includes(value) && tags.length < 30) {
        setTags([...tags, value]);
        event.target.value = "";
      }
    }
  };

  const handleDelete = (tagToDelete) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImagePreviews(files.map((file) => URL.createObjectURL(file)));
  };

  const handleVariantChange = (index, field, value) => {
    const newVariants = [...variants];
    newVariants[index][field] = value;
    setVariants(newVariants);
  };

  const addVariant = () => {
    setVariants([...variants, { color: "", size: "", price: 0, stock: 0 }]);
  };

  const removeVariant = (index) => {
    setVariants(variants.filter((_, i) => i !== index));
  };

  return (
    <>
      <DialogTitle>{label || "Add Product"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Controller
            name="subCategory"
            control={control}
            render={({ field }) => (
              <div style={{ width: "100%", marginTop: ".5rem" }}>
                <Select
                  {...field}
                  placeholder="Sub-Category"
                  options={filteredSubcategories}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(selectedOption) => field.onChange(selectedOption)}
                  value={field.value}
                />
                {errors.subCategory && (
                  <Typography color="error">
                    {errors.subCategory.message}
                  </Typography>
                )}
              </div>
            )}
          />
          <TextField
            sx={{ width: "100%", marginTop: ".5rem" }}
            placeholder="Product Name"
            {...register("productName")}
            error={!!errors.productName}
            helperText={errors.productName?.message}
          />
          <TextField
            sx={{ width: "100%", marginTop: ".5rem" }}
            type="number"
            placeholder="Items In Stock"
            {...register("itemsInStock")}
            error={!!errors.itemsInStock}
            helperText={errors.itemsInStock?.message}
          />
          <TextField
            sx={{ width: "100%", marginTop: ".5rem" }}
            type="number"
            placeholder="Product Price"
            {...register("productPrice")}
            error={!!errors.productPrice}
            helperText={errors.productPrice?.message}
          />
          <TextField
            sx={{ width: "100%", marginTop: ".5rem" }}
            type="number"
            placeholder="Discount"
            {...register("discount")}
            error={!!errors.discount}
            helperText={errors.discount?.message}
          />
          <TextField
            sx={{ width: "100%", marginTop: ".5rem" }}
            placeholder="SKU"
            {...register("SKU")}
            error={!!errors.SKU}
            helperText={errors.SKU?.message}
          />
          <TextField
            sx={{ width: "100%", marginTop: ".5rem" }}
            placeholder="Specification"
            {...register("specification")}
            error={!!errors.specification}
            helperText={errors.specification?.message}
          />
          <FormControl sx={{ width: "100%", marginTop: ".5rem" }}>
            <FormLabel>Special Item Status</FormLabel>
            <Controller
              name="specialItemStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              )}
            />
            {errors.specialItemStatus && (
              <Typography color="error">
                {errors.specialItemStatus.message}
              </Typography>
            )}
          </FormControl>
          <TextField
            sx={{ width: "100%", marginTop: ".5rem" }}
            type="number"
            placeholder="Token"
            {...register("token")}
            error={!!errors.token}
            helperText={errors.token?.message}
          />
          <TextField
            sx={{ width: "100%", marginTop: ".5rem" }}
            type="number"
            placeholder="Affiliate Token"
            {...register("affiliateToken")}
            error={!!errors.affiliateToken}
            helperText={errors.affiliateToken?.message}
          />
          <TextField
            sx={{ width: "100%", marginTop: ".5rem" }}
            placeholder="Add Tags (press Enter to add)"
            onKeyDown={handleKeyDown}
          />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              marginTop: ".5rem",
            }}
          >
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleDelete(tag)}
              />
            ))}
          </Box>
          {/* <input
            type="file"
            accept=".jpeg, .jpg, .png" // Restrict to JPEG, JPG, and PNG formats
            multiple
            {...register("images")}
            onChange={handleImageChange}
            style={{ marginTop: ".5rem" }}
          /> */}
          <label htmlFor="image-upload" style={{ marginTop: ".5rem" }}>
            Upload Images (JPEG, JPG, PNG):
            <input
              id="image-upload"
              type="file"
              accept=".jpeg, .jpg, .png" // Restrict to JPEG, JPG, and PNG formats
              multiple
              {...register("images")}
              onChange={handleImageChange}
              style={{ marginLeft: "0.5rem" }}
            />
          </label>
          <Box
            sx={{
              border: "1px dashed gray",
              margin: ".5rem 0",
              borderRadius: "1rem",
              width: "100%",
            }}
          >
            {imagePreviews.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Preview ${index}`}
                style={{ width: "100px", height: "100px", marginTop: ".5rem" }}
              />
            ))}
          </Box>
          {/* <input
            type="file"
            accept=".mp4" // Restrict to MP4 format
            {...register("video")}
            style={{ marginTop: ".5rem" }}
          /> */}
          <label htmlFor="video-upload" style={{ marginTop: ".5rem" }}>
            Upload Video (MP4):
            <input
              id="video-upload"
              type="file"
              accept=".mp4" // Restrict to MP4 format
              {...register("video")}
              style={{ marginLeft: "0.5rem" }}
            />
          </label>

          {videoPreview && (
            <video controls src={videoPreview} style={{ marginTop: ".5rem" }} />
          )}

          <div
            style={{
              marginTop: ".5rem",
              boxShadow: `${variants?.length > 0 ? "0 0 10px 1px gray" : ""}`,
              borderRadius: `${variants?.length > 0 ? ".5rem" : ""}`,
              width: "100%",
              padding: ".2rem",
            }}
          >
            {variants.map((variant, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  marginBottom: "1rem",
                }}
              >
                <TextField
                  placeholder="Color"
                  value={variant.color}
                  onChange={(e) =>
                    handleVariantChange(index, "color", e.target.value)
                  }
                  error={!!errors.variants?.[index]?.color}
                  helperText={errors.variants?.[index]?.color?.message}
                />
                <TextField
                  placeholder="Size"
                  value={variant.size}
                  onChange={(e) =>
                    handleVariantChange(index, "size", e.target.value)
                  }
                  error={!!errors.variants?.[index]?.size}
                  helperText={errors.variants?.[index]?.size?.message}
                />
                <TextField
                  type="number"
                  placeholder="Price"
                  value={variant.price}
                  onChange={(e) =>
                    handleVariantChange(index, "price", e.target.value)
                  }
                  error={!!errors.variants?.[index]?.price}
                  helperText={errors.variants?.[index]?.price?.message}
                />
                <TextField
                  type="number"
                  placeholder="Stock"
                  value={variant.stock}
                  onChange={(e) =>
                    handleVariantChange(index, "stock", e.target.value)
                  }
                  error={!!errors.variants?.[index]?.stock}
                  helperText={errors.variants?.[index]?.stock?.message}
                />
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => removeVariant(index)}
                  disabled={variants.length <= 1}
                >
                  Remove Variant
                </Button>
              </Box>
            ))}
            <Button
              variant="outlined"
              color="primary"
              onClick={addVariant}
              sx={{ marginTop: ".5rem" }}
            >
              Add Variant
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={()=>{handleAddProductToggle();handleClose();}}>Cancel</Button> */}
          <Button variant="contained" type="submit">
            {label ? "Update" : "Submit"}
          </Button>
        </DialogActions>
      </form>
    </>
  );
}

export default AddProduct;
