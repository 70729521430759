import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCategory from "../AddContainer/AddCategory";
import { centralizedApi } from "../../../services/API/centralizedApi";
import { toastError, toastSuccess } from "../../../utils/toastUtil";
import { getCatDat } from "../../../utils";

const CategoryContent = ({ page, tableData, callgetData,setTableData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editCategory, setEditCategory] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    console.log("Edit:", selectedRow);
    setEditCategory(true);
    // handleClose();
  };

  const handleDeleteModalCall = () => {
    console.log("Delete:", selectedRow);
    setDeleteCategory(true);
    // handleClose();
  };

  const handleDeleteCategory = async () => {
    try {
      const response = await centralizedApi.deleteCategory(
        selectedRow?.original?.id
      );
      console.log("response from delete category", response);
      setDeleteCategory(false);
      handleClose();
      // tableData.mutate()
      toastSuccess("Category Deleted Successfully");
      callgetData();
      // if (response?.status === 204) {
      // }
    } catch (error) {
      console.log("error while deleting category", error);
      toastError("Error while deleting category");
      setDeleteCategory(false);
      handleClose();
    }
  };

  // useEffect(() => {
  //   if (!editCategory && !deleteCategory) {
  //     callgetData();
  //   }
  // }, [editCategory, deleteCategory]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id", // Assuming id is required
        header: "ID",
        size: 80,
      },
      {
        accessorKey: "name", // Name of the item (category, product, etc.)
        header: "Name",
        size: 150,
      },
      {
        accessorKey: "image",
        header: "Image",
        size: 150,
        Cell: ({ cell }) => (
          <img
            src={cell.getValue()}
            alt="Item"
            style={{ width: 50, height: 50 }}
          />
        ),
      },
      {
        accessorKey: "details", // Details about the item
        header: "Details",
        size: 200,
      },
      {
        accessorKey: "discount", // Discount if applicable
        header: "Discount",
        size: 100,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleClick(event, row)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEdit}>
                <EditIcon sx={{ marginRight: "0.5rem" }} /> Edit
              </MenuItem>
              <MenuItem onClick={handleDeleteModalCall}>
                <DeleteIcon sx={{ marginRight: "0.5rem" }} /> Delete
              </MenuItem>
            </Menu>
          </Box>
        ),
      },
    ],
    [anchorEl]
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData, // Use the dynamic tableData
  });

  const pageName =
    page === "Category"
      ? "Categories"
      : page === "Sub-Category"
      ? "Sub-Categories"
      : page === "Product"
      ? "Products"
      : page === "Orders"
      ? "Orders"
      : "Images";

  return (
    <Box sx={{ margin: "1rem" }}>
      <Typography
        variant="h4"
        sx={{ textAlign: "left", margin: "1rem 0" }}
        className="text-primary"
      >
        {pageName}
      </Typography>
      {pageName !== "Images" && (
        <MaterialReactTable table={table} style={{ margin: "2rem" }} />
      )}
      {pageName === "Images" && (
        <Box>
          <Button variant="contained">Add Advertisement</Button>
          <Button variant="contained">Add Banner</Button>
        </Box>
      )}
      {/* { */}
      {/* Edit Dialog */}
      <Dialog
        open={editCategory}
        onClose={() => {
          setEditCategory(false);
          handleClose();
        }}
      >
        <AddCategory
          label="Edit Category"
          data={selectedRow}
          setEditCategory={setEditCategory}
          handleClose={handleClose}
          setTableData={setTableData}
        />
      </Dialog>

      {/* Delete Dialog */}
      <Dialog
        open={deleteCategory}
        onClose={() => {
          setDeleteCategory(false);
          handleClose();
        }}
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this category?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteCategory(false);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteCategory();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* } */}
    </Box>
  );
};

export default CategoryContent;
