// PolicyUtils.js
export const termsAndConditions = `
  Introduction By accessing or using Quirckart.com, you agree to comply with these Terms and Conditions. Please read them carefully before placing any orders.

Product Information We strive to ensure that all product descriptions and images on Quirckart.com are accurate. However, we do not guarantee the complete accuracy of colors or specifications as they may vary slightly from the actual product.

Pricing and Payment All prices listed on Quirckart.com are in INR and are subject to change without prior notice. Payments must be made in full via the available payment methods at checkout. We do not accept cash on delivery.

Order Acceptance We reserve the right to accept or reject any order. If an order is canceled, you will receive a notification, and any payment made will be refunded in full.

Shipping and Delivery We deliver to most regions and will make every effort to ship orders promptly. However, delivery times may vary depending on the location and availability of the product. Any shipping delays will be communicated to you.

Return and Refund Policy If you are not satisfied with your purchase, you may return it within 14 days of receipt, subject to our return policy guidelines. Refunds will only be processed once the item has been inspected and confirmed to be in its original condition.

User Accounts You are responsible for maintaining the confidentiality of your account details, including your password. Quirckart.com reserves the right to suspend or terminate your account in the event of suspicious or fraudulent activity.

Limitation of Liability Quirckart.com is not liable for any indirect, incidental, or consequential damages arising from the use of or inability to use our website or products. Our liability is limited to the total purchase price of your order.

Intellectual Property All content, including text, images, and logos on Quirckart.com, are the property of Quirckart and its affiliates. Unauthorized use of our intellectual property is strictly prohibited.

Governing Law These Terms and Conditions are governed by the laws of GOVT. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts.


`;

export const shippingPolicy = `
 1. Shipping Timeframes
We strive to process and ship all orders within 1-2 business days. Depending on your location, delivery times may vary, typically ranging from 3-7 business days for standard shipping.
2. Shipping Costs
Shipping charges are calculated based on the weight, dimensions, and destination of your order. You will see the shipping cost during checkout before completing your purchase.
3. Free Shipping
Quirckart offers free standard shipping on all orders over INR 1000 within the India. This offer excludes expedited shipping and certain remote locations.
4. Expedited Shipping
For faster delivery, we offer expedited shipping options at an additional cost. You can choose express shipping at checkout, and your order will be delivered within 1-3 business days.
5. International Shipping
Quirckart ships internationally to select countries. International shipping rates and delivery times will be calculated at checkout based on the destination country and order weight.
6. Tracking Your Order
Once your order has been shipped, you will receive a shipping confirmation email with a tracking number. You can use this number to track your order's status on our website.
7. Shipping Restrictions
Some items may have restrictions on delivery to certain locations, such as remote areas or international destinations. We will notify you at checkout if any items in your cart cannot be shipped to your address.
8. Address Accuracy
Please ensure your shipping address is correct when placing an order. Quirckart is not responsible for orders shipped to incorrect addresses due to customer errors.
9. Delayed or Lost Shipments
While we strive for timely deliveries, delays may occur due to unforeseen circumstances (e.g., weather, carrier issues). If your order is delayed or lost, please contact our customer service team for assistance.
10. Damaged or Missing Items
If your package arrives damaged or with missing items, please notify us within 7 days of delivery. We will work with you to provide a replacement or refund, subject to our return policy.
`;

// Add more policies as needed
export const privacyPolicy = `
  Information Collection
We collect personal information such as name, email address, contact details, shipping address, and payment information during the account creation, checkout process, and when users interact with the website. This helps us fulfill orders and improve customer service.

Use of Information
The information collected is used to process transactions, deliver products, manage customer accounts, and send updates regarding orders. Occasionally, we may use this information to send promotional content or special offers related to your interests.

Data Security
We implement strict security measures to safeguard personal information. Sensitive information, such as payment details, is encrypted using secure socket layer (SSL) technology to ensure it is protected during transmission.

Third-Party Services
Quirckart.com may partner with third-party service providers (e.g., payment processors, shipping partners) who assist us in delivering services. These third parties are obligated to maintain the confidentiality and security of the data provided to them.

Cookies and Tracking Technologies
We use cookies and similar technologies to track user behavior on our website. These help us understand preferences, enhance site navigation, and personalize your shopping experience. You can manage cookie settings through your browser.

Marketing and Communication
With your consent, we may use your contact information to send marketing communications such as newsletters, special offers, and product updates. You can opt-out of these communications at any time.

Sharing of Information
We do not sell, rent, or trade personal information with third parties for marketing purposes. However, we may share data with third-party partners to process orders, conduct business, or comply with legal obligations.

Children’s Privacy
Quirckart.com is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have collected such information, it will be promptly deleted.

Your Rights
Users have the right to access, correct, or delete their personal information. If you wish to make changes to your data or request its deletion, please contact us at info@quirckart.com.

Changes to Privacy Policy
Quirckart.com reserves the right to modify this privacy policy at any time. Any changes will be posted on this page, and we encourage users to review the policy periodically to stay informed about how we protect your data.


`;


export const cancellationpolicy = `
 Order Cancellation Before Dispatch
Customers can cancel their order anytime before the order is dispatched. To initiate cancellation, visit the ‘My Orders’ section and select the item to cancel.

Cancellation After Dispatch
Once an order is dispatched, cancellations are not allowed. In such cases, you can return the product after it is delivered, subject to our return and refund policy.

Partial Order Cancellation
Customers may cancel individual items in their order as long as the specific item has not been dispatched. Simply select the item in ‘My Orders’ and cancel it.

Cancellation Charges
No cancellation charges will be applied if the order is canceled before dispatch. For orders canceled after dispatch or rejected upon delivery, a minimal processing fee may be deducted from the refund.

Instant Refund on Cancellation
If an order is canceled before dispatch, the refund will be processed immediately and will reflect in your account within 6-8 business days.

Non-cancellable Items
Certain items like perishable goods, customized products, and limited-time offers are non-cancellable. Details will be provided in the product description.

Refund Process After Cancellation
If a cancellation request is accepted, the refund will be issued via the same payment method used during purchase, with the amount reflecting in your account within 7-10 business days.

Cancellation Due to Unavailability
In rare cases where a product becomes unavailable after your order is placed, Quirckart reserves the right to cancel the order and issue a full refund.

Cancellation on Cash-on-Delivery Orders
Customers who chose the Cash-on-Delivery option can cancel their order before dispatch without any penalty. For returns after delivery, standard return policies will apply.

Cancellation Policy for Bulk Orders
For bulk or large orders, cancellation requests may be subject to additional terms and conditions. Please contact customer support for assistance.
`;
