import React, { useEffect, useState } from "react";
import { Dialog, Drawer } from "@mui/material";

import DrawerNav from "../../components/DrawerNav";
import AdminNav from "../../components/Admin/AdminNav";
import AdminContent from "../../components/Admin/AdminContent";
import AddContainer from "../../components/Admin/AddContainer";
import AddCategory from "../../components/Admin/AddContainer/AddCategory";
import AddSubCategory from "../../components/Admin/AddContainer/AddSubCategory";
import AddProduct from "../../components/Admin/AddContainer/AddProduct";
import { handleGetData } from "../../utils";

function AdminDashboard() {
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => setOpen((prev) => !prev);

  const [addProductOpen, setAddProductOpen] = useState(false);
  const handleAddProductToggle = () => {
    setAddProductOpen((prev) => !prev);
  };
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const handleAddCategoryToggle = () => {
    setAddCategoryOpen((prev) => !prev);
  };
  const [addSubCategoryOpen, setAddSubCategoryOpen] = useState(false);
  const handleAddSubCategoryToggle = () => {
    setAddSubCategoryOpen((prev) => !prev);
  };

  const [page, setPage] = useState("Category");
  const [tableData, setTableData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(()=>{
    async function callgetData(){

      if(!tableData?.length>0){
  
        const categoryData = await handleGetData('categories')
        setTableData(categoryData)
      }
    }

    callgetData()

  },[])

  return (
    <>
      <AdminNav handleDrawerOpen={handleDrawerOpen} />

      <Drawer open={open} onClose={handleDrawerOpen}>
        <DrawerNav
          setPage={setPage}
          handleDrawerOpen={handleDrawerOpen}
          tableData={tableData}
          setTableData={setTableData}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </Drawer>

      <AddContainer
        page={page}
        handleAddCategoryToggle={handleAddCategoryToggle}
        handleAddSubCategoryToggle={handleAddSubCategoryToggle}
        handleAddProductToggle={handleAddProductToggle}
      />

      <AdminContent page={page} tableData={tableData} />

      {/* **********Add Category Dialog*********** */}
      <Dialog open={addCategoryOpen} onClose={handleAddCategoryToggle}>
        <AddCategory />
      </Dialog>

      {/* ***********Add SubCategory Dialog********** */}
      <Dialog open={addSubCategoryOpen} onClose={handleAddSubCategoryToggle}>
        <AddSubCategory />
      </Dialog>

      {/* **********Add Product/Item Dialog*********** */}
      <Dialog open={addProductOpen} onClose={handleAddProductToggle}>
        <AddProduct />
      </Dialog>
    </>
  );
}

export default AdminDashboard;
