// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {getAuth} from 'firebase/auth'
const firebaseConfig = {
  apiKey: "AIzaSyB083idssmE3AEGuv7qFrGyPXUKlqAencc",
  authDomain: "quirckart-infotech.firebaseapp.com",
  databaseURL: "https://quirckart-infotech-default-rtdb.firebaseio.com",
  projectId: "quirckart-infotech",
  storageBucket: "quirckart-infotech.appspot.com",
  messagingSenderId: "1011544239867",
  appId: "1:1011544239867:web:2a6bf2ee92a7b2aed60a5e",
  measurementId: "G-23Q19ME2QQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
// const analytics = getAnalytics(app);