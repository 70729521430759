
import { Navigate, useNavigate } from 'react-router-dom';
import { addToCart, updateCartQuantity, removeFromCart } from '../store/slices/UserDetailsSlice';
import { toastSuccess } from './toastUtil';

// Add or update the cart item
export const handleAddToCart = (product, dispatch, quantity, cartItems) => {
  console.log("product coming to add to cart", product, quantity)
  const cartItemsInLocalStorage = JSON.parse(localStorage.getItem("cartItems")) || [];
  const existingProduct = cartItemsInLocalStorage.find(item => item.id === product.id);
  // console.log("item found in cart")
  if (existingProduct) {
    // Increase quantity if product already exists
    const updatedCart = cartItemsInLocalStorage.map(item =>
      item.id === product.id ? { ...item, quantity: item.quantity + quantity? quantity:1 } : item
    );
    console.log('updated cart is: ', updatedCart)
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCart));
    dispatch(updateCartQuantity({ id: product.id, quantity: existingProduct.quantity + quantity?quantity:1 }));
  } else {
    // Add new product to cart with quantity 1
    const updatedCart = [...cartItemsInLocalStorage, { ...product, quantity: quantity?quantity:1 }];
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCart));
    dispatch(addToCart({ ...product, quantity: quantity?quantity:1 }));
  }
  toastSuccess("Item added to cart");
};

// Increase the quantity of an item
export const increaseCartItemQuantity = (productId, dispatch) => {
  const cartItemsInLocalStorage = JSON.parse(localStorage.getItem("cartItems")) || [];
  const updatedCart = cartItemsInLocalStorage.map(item =>
    item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
  );
  localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  sessionStorage.setItem("cartItems", JSON.stringify(updatedCart));
  dispatch(updateCartQuantity({ id: productId, quantity: updatedCart.find(item => item.id === productId).quantity }));
};

// Decrease the quantity of an item (remove if quantity is 1)
export const decreaseCartItemQuantity = (productId, dispatch) => {
  const cartItemsInLocalStorage = JSON.parse(localStorage.getItem("cartItems")) || [];
  const existingProduct = cartItemsInLocalStorage.find(item => item.id === productId);

  if (existingProduct.quantity > 1) {
    // Decrease quantity
    const updatedCart = cartItemsInLocalStorage.map(item =>
      item.id === productId ? { ...item, quantity: item.quantity - 1 } : item
    );
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCart));
    dispatch(updateCartQuantity({ id: productId, quantity: existingProduct.quantity - 1 }));
  } else {
    // If quantity is 1, remove the item
    removeCartItem(productId, dispatch);
  }
};

// Remove an item from the cart
export const removeCartItem = (productId, dispatch) => {
  const cartItemsInLocalStorage = JSON.parse(localStorage.getItem("cartItems")) || [];
  const updatedCart = cartItemsInLocalStorage.filter(item => item.id !== productId);
  localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  sessionStorage.setItem("cartItems", JSON.stringify(updatedCart));
  dispatch(removeFromCart(productId));
  toastSuccess("Item removed from cart");
};


export const logoutAffiliate = (navigate)=>{
  sessionStorage.removeItem('affiliateAccess');
  sessionStorage.removeItem('affiliateRefresh')
  sessionStorage.removeItem('affiliate_username')
  navigate('/affiliate')
}