import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import UserRoute from './UserRoute';
import AdminRoute from "./AdminRoute";
import HomePage from "../pages/HomePage";
import AdminSignupLogin from "../pages/AdminSignupLogin";
import AdminDashboard from "../pages/AdminDashboard";
import NotFound from "../pages/NotFound";
import Categories from "../components/Admin/AdminSections/Categories";
import SubCategories from "../components/Admin/AdminSections/SubCategories";
import Orders from "../components/Admin/AdminSections/Orders";
import AddAdvertise from "../components/Admin/AdminSections/AddAdvertise";
import Products from "../components/Admin/AdminSections/Products";
import ChangeWebInfo from "../components/Admin/AdminSections/ChangeWebInfo";
import AdminLayout from "../components/Admin/AdminLayout";
import UserLayout from "../components/User/UserLayout";
import UserCart from "../components/User/UserPages/UserCart";
import UserCategory from "../components/User/UserPages/UserCategory";
import UserSubCategory from "../components/User/UserPages/UserSubCategory";
import UserProduct from "../components/User/UserPages/UserProduct";
import UserCheckout from "../components/User/UserPages/UserCheckout";
import UserLogin from "../components/User/UserLogin";
import UserOrders from "../components/User/UserOrders";
import UserSearch from "../components/User/UserSearch";
import Affiliate from "../components/Affiliate";
import AffiliateDashboard from "../components/Affiliate/AffiliateDashboard";
import AffiliateLogin from "../components/Affiliate/AffiliateLogin";
import AffiliateProduct from "../components/Affiliate/AffiliateProduct";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToCart, resetCart } from "../store/slices/UserDetailsSlice";
const App = () => {
  const dispatch = useDispatch();
  const userAccessToken = sessionStorage.getItem('userAccessToken')
  const userSession = JSON.parse(sessionStorage.getItem('userSession'))
  const getUserData = async () => {
    try {
      const response = await axios.post('http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/api/customer-search-create/', {

        username: userSession?.user?.phoneNumber,
        password: userSession?.user?.phoneNumber
      })
      let createData = response?.data
      console.log('user details', response?.data)
      try {
        const response = await axios.get(`http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/api/customers/${createData?.customer_id}`)

        const data = response?.data;
        sessionStorage.setItem("userDetailsFetch", JSON.stringify(data))
        sessionStorage.setItem("cartItems", JSON.stringify(data?.cart_items_detail))
        dispatch(resetCart(data?.cart_items_detail))
      } catch (error) {
        console.log(error);
      }
      // dispatch( setCategoryData(response?.data))
    } catch (error) {
      console.log("error while getting user category data", error?.response?.message)
    }
  }

  useEffect(() => {
    if (userAccessToken) {
      getUserData();
    }

  }, [userAccessToken])
  useEffect(()=>{
    const cartLocalItems = localStorage.getItem('cartItems')
    if(!userAccessToken && cartLocalItems){
      dispatch(addToCart(JSON.parse(cartLocalItems)))
    }
  },[])
  return (
    <Router>
      <Routes>
        {/* User Routes */}
        {/* <Route path="/" element={<HomePage />} /> */}
        {/* User Routes wrapped with UserLayout */}
        <Route element={<UserLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="login" element={<UserLogin />} />
          <Route path="affiliate-registration" element={<Affiliate />} />
          <Route path="affiliate" element={<AffiliateLogin />} />
          <Route path="affiliate-dashboard" element={<AffiliateDashboard />} />
          <Route path="cart" element={<UserCart />} />
          <Route path="checkout" element={<UserCheckout />} />
          <Route path="orders" element={<UserOrders />} />
          <Route path="/category/:categoryId" element={<UserCategory />} />
          <Route
            path="/category/:categoryId/subcategory/:subCategoryId"
            element={<UserSubCategory />}
          />
          <Route
            path="category/:categoryId/subcategory/:subCategoryId/product/:productId"
            element={<UserProduct />}
          />
          <Route
            path="/api/share/:UID"
            element={<AffiliateProduct />}
          />
          <Route
            path="search/:searchQuery"
            element={<UserSearch />}
          />
        </Route>
        {/* Admin Routes */}
        <Route
          path="/admin-dashboard/"
          element={
            <AdminRoute>
              <AdminLayout />
            </AdminRoute>
          }
        >
          <Route path="categories" element={<Categories />} />
          <Route path="subCategories" element={<SubCategories />} />
          <Route path="products" element={<Products />} />
          <Route path="orders" element={<Orders />} />
          <Route path="addAdvertisement" element={<AddAdvertise />} />
          <Route path="changeWebInfo" element={<ChangeWebInfo />} />
        </Route>
        {/* Public Routes */}
        <Route path="/admin-login" element={<AdminSignupLogin />} />
        {/* Catch-all route for 404 Not Found */}
        <Route path="*" element={<NotFound />} />{" "}
        {/* This handles all unmatched routes */}
      </Routes>
    </Router>
  );
};

export default App;
