import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import quirckartLogo from "../../assets/images/quirckart.png";
import { centralizedApi } from "../../services/API/centralizedApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSharedContext } from "../../services/ContextProvider";
import { CircularProgress } from '@mui/material';
const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  padding: "20px",
  backgroundColor: "#f0f4f8",
});

const FormWrapper = styled("div")({
  backgroundColor: "#fff",
  padding: "40px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  width: "100%",
  maxWidth: "400px",
});

const SwitchButton = styled(Button)({
  marginTop: "20px",
});

const AdminSignupLogin = () => {
  const [isSignup, setIsSignup] = useState(false);
  const { loading, setLoading } = useSharedContext();
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const signupLoginFunc = async (data) => {
    try {
      const response = await centralizedApi.signupLogin(data);
      setLoading(false)
      if (response?.status === 'success' && response?.csrf) {
        console.log("response from signup/login", response);
        // Handle successful response
        document.cookie = `adminToken=${response.csrf}; path=/; SameSite=Strict`;
        document.cookie = `adminSessionId=${response.sessionid}; path=/; SameSite=Strict`;
        navigate("/admin-dashboard/categories");
      }
    } catch (error) {
      setLoading(false)
      // Handle error
      throw error;
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    toast.promise(
      signupLoginFunc(data),
      {
        loading: "Loading...",
        success:`${isSignup ? "Sign Up" : "Login"} successful!`,
        error: "Error occurred during the operation.",
      }
    );
    reset(); // Reset form fields after submission
  };

  const toggleForm = () => {
    setIsSignup(!isSignup);
    reset({ password: "" }); // Reset the password field
  };

  return (
    <Container>
      <FormWrapper>
        <img src={quirckartLogo} style={{ width: "100px" }} alt="quirckart" />
        <h2 className="text-2xl font-bold text-center mb-6">
          Admin/Staff
          <span className="text-primary">
            {isSignup ? " Sign Up" : " Login"}
          </span>
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-4">
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              {...register("username", {
                required: "Username is required",
                minLength: {
                  value: 4,
                  message: "Username must be at least 4 characters long",
                },
              })}
              error={!!errors.username}
              helperText={errors.username?.message}
            />
          </div>

          <div className="mb-4">
            <TextField
              fullWidth
              label="Password"
              type="password"
              variant="outlined"
              {...register("password", {
                required: isSignup ? "Password is required" : false,
                pattern: isSignup
                  ? {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        "Password must contain an uppercase letter, a lowercase letter, a number, and a special character",
                    }
                  : {},
              })}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          </div>

          <Button
            type="submit"
            variant="contained"
            className="w-full py-2 bg-secondary"
            disabled={loading}
          >
            {isSignup ? `Sign Up ` : `Login `} {loading?<CircularProgress size={20} sx={{marginLeft:'1rem'}}/>:""}
          </Button>
        </form>

        <SwitchButton
          variant="text"
          color="primary"
          onClick={toggleForm}
          className="w-full"
        >
          {isSignup
            ? "Already have an account? Login"
            : "Don't have an account? Sign Up"}
        </SwitchButton>
      </FormWrapper>
    </Container>
  );
};

export default AdminSignupLogin;
