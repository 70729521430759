import React, { useState, useEffect } from 'react';
import './VideoGallery.css';

const VideoGallery = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating video fetching
    const mockVideos = [
      {
        id: 1,
        video_url: 'https://testingbucketboto3.s3.amazonaws.com/www.reallygreatsite.com+(1).mp4',
        product_link: 'https://yourwebsite.com/product1',
        description: 'Product 1 Video'
      },
      {
        id: 2,
        video_url: 'https://testingbucketboto3.s3.amazonaws.com/www.reallygreatsite.com+(2).mp4',
        product_link: 'https://yourwebsite.com/product2',
        description: 'Product 2 Video'
      },
      {
        id: 3,
        video_url: 'https://testingbucketboto3.s3.amazonaws.com/www.reallygreatsite.com+(3).mp4',
        product_link: 'https://yourwebsite.com/product3',
        description: 'Product 3 Video'
      },
      {
        id: 4,
        video_url: 'https://testingbucketboto3.s3.amazonaws.com/www.reallygreatsite.com+(5).mp4',
        product_link: 'https://yourwebsite.com/product4',
        description: 'Product 4 Video'
      },
      {
        id: 4,
        video_url: 'https://testingbucketboto3.s3.amazonaws.com/www.reallygreatsite.com+(4).mp4',
        product_link: 'https://yourwebsite.com/product4',
        description: 'Product 4 Video'
      },
      {
        id: 3,
        video_url: 'https://testingbucketboto3.s3.amazonaws.com/www.reallygreatsite.com+(3).mp4',
        product_link: 'https://yourwebsite.com/product3',
        description: 'Product 3 Video'
      },
      {
        id: 4,
        video_url: 'https://testingbucketboto3.s3.amazonaws.com/www.reallygreatsite.com+(5).mp4',
        product_link: 'https://yourwebsite.com/product4',
        description: 'Product 4 Video'
      },
    ];

    setTimeout(() => {
      setVideos(mockVideos);
      setLoading(false);
    }, 1000);
  }, []);


  return (
    <div className="video-gallery">
     
      {loading ? (
        <p>Loading videos...</p>
      ) : (
        <div className="video-scroll-container">
          {videos.map((video) => (
            <div key={video.id} className="video-item">
              <video
                src={video.video_url}
                autoPlay
                loop
                muted
                playsInline
                className="video"
                aria-label={video.description}
              />
              <a href={video.product_link} className="shop-now-btn" target="_blank" rel="noopener noreferrer">
                Shop Now
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VideoGallery;