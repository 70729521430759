import React from 'react'
import { LazyLoadImage} from 'react-lazy-load-image-component'
import "react-lazy-load-image-component/src/effects/blur.css";
export default function Image({src, styles, alt, className}) {
    return (
      <>
        <LazyLoadImage
          alt={alt}
          className={className}
          effect="blur"
          src={src}
          width="100%"
          height="100%"
          style={styles}
        />
      </>
    );
  }