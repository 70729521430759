import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, Dialog } from "@mui/material";
import AddCategory from "../../AddContainer/AddCategory";
import AddProduct from "../../AddContainer/AddProduct";
import AdminContent from "../../AdminContent";
import ProductsContent from "../../AdminContent/ProductsContent";
import { handleGetData } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategories,
  setProducts,
  setSubcategories,
} from "../../../../store/slices/categorySlice";

function Products() {
  const [addProductOpen, setAddProductOpen] = useState(false);
  const [pageTableData, setPageTableData] = useState([]);
  const categories = useSelector((state) => state.categories.categories);
  const subCategories = useSelector((state) => state.categories.subcategories);
  console.log("subcategories data is in products", subCategories)
  const dispatch = useDispatch();
  const handleAddProductToggle = () => {
    setAddProductOpen((prev) => !prev);
  };
  const { page, tableData, setTableData } = useOutletContext();
  async function callgetData() {
    const productsData = await handleGetData("items");
    setPageTableData(productsData?.results);
    dispatch(setProducts(productsData));
  }
  useEffect(() => {

    callgetData();
  }, []);

  async function callgetCatData() {
    const categoryData = await handleGetData("categories");
    dispatch(setCategories(categoryData));
  }

  useEffect(() => {
    callgetCatData();
  }, []);

  async function callgetSubCatData() {
    const subcategoryData = await handleGetData("subcategories");
    dispatch(setSubcategories(subcategoryData));
  }

  useEffect(() => {
    callgetSubCatData();
  }, []);

    // Trigger data fetch when the dialog closes
    useEffect(() => {
      if (!addProductOpen) {
        callgetData();
      }
    }, [addProductOpen]);

  return (
    <div>
      <div className="w-full flex justify-end px-4">
        <Button onClick={handleAddProductToggle} variant="contained">
          Add Product
        </Button>
      </div>
      <ProductsContent
        page={"Product"}
        tableData={pageTableData}
        subcategories={subCategories}
      />
      {/* **********Add Category Dialog*********** */}
      <Dialog open={addProductOpen} onClose={handleAddProductToggle}>
        <AddProduct
          handleAddProductToggle={handleAddProductToggle}
          setTableData={setTableData}
          categories={categories}
          subcategories={subCategories}
        />
      </Dialog>
    </div>
  );
}

export default Products;
