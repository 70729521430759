// import React from 'react';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import './HomeAdv.css'; // Make sure to create this CSS file for custom styles
// // import { homeAdvData as adData } from '../../../utils/dummyData';
// import Image from '../../GlobalComponents/Image';
// import { useSelector } from 'react-redux';

// function ScrollHomeAdv() {
//     const {advData} = useSelector(state=>state.userJourneyData)
//     console.log("advData data in scrollHomeadv", advData);
    
//     let landscapeImages = advData?.[0]?.landscape_images;

    
//     const settings = {
//         // dots: true, // Shows navigation dots
//         infinite: true, // Infinite looping
//         speed: 500, // Transition speed
//         slidesToShow: 1, // Shows one slide at a time
//         slidesToScroll: 1, // Scrolls one slide at a time
//         autoplay: true, // Enables autoplay
//         autoplaySpeed: 3000, // 3 seconds per slide
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 1, // Adjusts to show one slide for larger screens
//                 }
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 1, // Adjusts to show one slide for medium screens
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1, // Adjusts to show one slide for small screens
//                 }
//             }
//         ]
//     };


//     return (
//         <div className="ads-carousel"  >
//             <Slider {...settings}>
//                 {landscapeImages?.map((ad, index) => (
//                     <div key={index} className="ad-item">
//                         <Image src={ad.image} alt={"Landscape"} className="ad-image" style={{border:'none'}}/>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     );
// }

// export default ScrollHomeAdv;

// import React from 'react';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import './HomeAdv.css'; // Make sure to create this CSS file for custom styles
// // import { homeAdvData as adData } from '../../../utils/dummyData';
// import Image from '../../GlobalComponents/Image';
// import { useSelector } from 'react-redux';

// function ScrollHomeAdv() {
//     const {advData} = useSelector(state=>state.userJourneyData)
//     console.log("advData data in scrollHomeadv", advData);
    
//     let landscapeImages = advData?.[0]?.landscape_images;

    
//     const settings = {
//         // dots: true, // Shows navigation dots
//         infinite: true, // Infinite looping
//         speed: 500, // Transition speed
//         slidesToShow: 1, // Shows one slide at a time
//         slidesToScroll: 1, // Scrolls one slide at a time
//         autoplay: true, // Enables autoplay
//         autoplaySpeed: 3000, // 3 seconds per slide
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 1, // Adjusts to show one slide for larger screens
//                 }
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 1, // Adjusts to show one slide for medium screens
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1, // Adjusts to show one slide for small screens
//                 }
//             }
//         ]
//     };


//     return (
//         <div className="ads-carousel"  >
//             <Slider {...settings}>
//                 {landscapeImages?.map((ad, index) => (
//                     <div key={index} className="ad-item">
//                         <Image src={ad.image} alt={"Landscape"} className="ad-image" style={{border:'none'}}/>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     );
// }

// export default ScrollHomeAdv;

import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './HomeAdv.css'; // Ensure you have your CSS file for custom styles
import { useSelector } from 'react-redux';

function ScrollHomeAdv() {
    const { advData } = useSelector(state => state.userJourneyData);
    console.log("advData data in ScrollHomeAdv", advData);
    
    // Assuming landscape_images contains video URLs instead of image URLs
    let landscapeVideos = advData?.[0]?.landscape_images; // Change the data structure if needed

    const settings = {
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="ads-carousel">
            <Slider {...settings}>
                {landscapeVideos?.map((ad, index) => (
                    <div key={index} className="ad-item">
                        <video className="ad-video" autoPlay loop muted>
                            <source src={"https://res.cloudinary.com/dv9iswy2f/video/upload/v1730298251/wwpywgvqawxctqc9q2ll.mp4"} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default ScrollHomeAdv;
