import React from "react";
import List from "@mui/material/List";
import ListItemButton from "./ListItemButton";
import { Box } from "@mui/material";
import { handleGetData } from "../../utils";
import { useNavigate } from "react-router-dom";
function DrawerNav({ setPage, handleDrawerOpen, setTableData, activeIndex, setActiveIndex }) {
  const navigate = useNavigate()
  const items = ["Category", "Sub-Category", "Product", "Orders","Add Advertiesment","Add Web Info"];
  const apiItems = ["categories", "subcategories", "items", "orders", "addAdvertisement", "changeWebInfo"];
  const handleItemClick = (index) => {
    setActiveIndex(index);
    handleDrawerOpen()
    let page = apiItems[index]
    if(page === "items"){
      page = "products"
    }
    // let item = page==="items" ? page === "products" : page 
    console.log("navigate to", page)
    navigate(page);
  };

  // async function callHandleGetDataFunc(item){
  //   console.log("item in call data func ", item)
  //   let data = await handleGetData(item)
  //   console.log("handlegetdata is ", data)
  //   if(data){
  //     setTableData(data)
  //   }
  // }
  return (
    <Box sx={{ width: "300px", padding: ".5rem" }}>
      <List>
        {items.map((item, index) => (
          <ListItemButton
            key={index}
            text={item}
            style={{ padding: ".5rem 0" }}
            isActive={index === activeIndex}
            onClick={() => {
              handleItemClick(index);
              setPage(item);
              // callHandleGetDataFunc(apiItems[index])
              // handleDrawerOpen()
              
            }}
          />
        ))}
      </List>
    </Box>
  );
}

export default DrawerNav;
