import React, { useState, useEffect } from 'react';
import "./ProductComponent.css"; // Importing the CSS file for styling

const ProductComponent = () => {
    const [products, setProducts] = useState([]);
    const [advertImage, setAdvertImage] = useState(null);
    const [advertLink, setAdvertLink] = useState("https://example.com/explore"); // Add your advertisement link here

    useEffect(() => {
        const dummyData = {
            products: [
                {
                    name: "Samsung Galaxy S23",
                    image: "https://testingbucketboto3.s3.amazonaws.com/11.png",
                    discount: "Min. 50% Off",
                    link: "https://example.com/samsung-galaxy-s23"
                },
                {
                    name: "Sports & Action Camera",
                    image: "https://testingbucketboto3.s3.amazonaws.com/22.png",
                    discount: "Min. 30% Off",
                    link: "https://example.com/sports-action-camera"
                },
                {
                    name: "Projector",
                    image: "https://testingbucketboto3.s3.amazonaws.com/44.png",
                    discount: "Min. 50% Off",
                    link: "https://example.com/projector"
                },
                {
                    name: "Memory Card",
                    image: "https://testingbucketboto3.s3.amazonaws.com/33.png",
                    discount: "Min. 50% Off",
                    link: "https://example.com/memory-card"
                }
            ],
            advertImage: "https://testingbucketboto3.s3.amazonaws.com/Untitled+design.png"
        };

        setProducts(dummyData.products);
        setAdvertImage(dummyData.advertImage);
    }, []);

    return (
        <div className="flex-container">
            {/* Products List */}
            <div className="products">
                <div className="product-grid">
                    {products.map((product, index) => (
                        <div key={index} className="product-card border">
                            <a href={product.link} target="_blank" rel="noopener noreferrer">
                                <img src={product.image} alt={product.name} className="w-full mb-2" />
                            </a>
                            <h3 className="text-lg">{product.name}</h3>
                            <p className="text-green-600">{product.discount}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Advertisement Image */}
            <div className="advertisement">
                <img src={advertImage} alt="Advertisement" className="w-full h-full object-cover rounded-lg" />
                <div className="advert-button">
                    <a href={advertLink} target="_blank" rel="noopener noreferrer">
                        <button className="explore-button">Explore Now</button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ProductComponent;
