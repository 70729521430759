

import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddSubCategory from "../AddContainer/AddSubCategory";
import { toastError, toastSuccess } from "../../../utils/toastUtil";
import { centralizedApi } from "../../../services/API/centralizedApi";

const SubCategoryContent = ({ page, tableData, categories }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editSubCategory, setEditSubCategory] = useState(false);
  const [deleteSubCategory, setDeleteSubCategory] = useState(false);
  console.log("selected row in sub category:", selectedRow);
  console.log('categories in sub cat content', categories);
  
  
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    setEditSubCategory(true);
    // handleClose();
  };

  const handleDelete = () => {
    setDeleteSubCategory(true);
    // handleClose();
  };

  
  const handleDeleteCategory = async () => {
    try {
      const response = await centralizedApi.deleteSubCategory(
        selectedRow?.original?.id
      );
      console.log("response from delete sub-category", response);
      setDeleteSubCategory(false);
      handleClose();
      // tableData.mutate()
      toastSuccess("Sub-Category Deleted Successfully");
      // callgetData();
      window.location.reload()
      // if (response?.status === 204) {
      // }
    } catch (error) {
      console.log("error while deleting sub-category", error);
      toastError("Error while deleting sub-category");
      setDeleteSubCategory(false);
      handleClose();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 80,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 150,
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 150,
      },
      {
        accessorKey: "image",
        header: "Image",
        size: 150,
        Cell: ({ cell }) => (
          <img
            src={cell.getValue()}
            alt="Item"
            style={{ width: 50, height: 50 }}
          />
        ),
      },
      {
        accessorKey: "details",
        header: "Details",
        size: 200,
      },
      {
        accessorKey: "discount",
        header: "Discount",
        size: 100,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleClick(event, row)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEdit}>
                <EditIcon sx={{ marginRight: "0.5rem" }} /> Edit
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <DeleteIcon sx={{ marginRight: "0.5rem" }} /> Delete
              </MenuItem>
            </Menu>
          </Box>
        ),
      },
    ],
    [anchorEl]
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData,
  });

  const pageName =
    page === "Category"
      ? "Categories"
      : page === "Sub-Category"
      ? "Sub-Categories"
      : page === "Product"
      ? "Products"
      : page === "Orders"
      ? "Orders"
      : "Images";

  return (
    <Box sx={{ margin: "1rem" }}>
      <Typography
        variant="h4"
        sx={{ textAlign: "left", margin: "1rem 0" }}
        className="text-primary"
      >
        {pageName}
      </Typography>
      {pageName !== "Images" && (
        <MaterialReactTable table={table} style={{ margin: "2rem" }} />
      )}
      {pageName === "Images" && (
        <Box>
          <Button variant="contained">Add Advertisement</Button>
          <Button variant="contained">Add Banner</Button>
        </Box>
      )}

      <Dialog
        open={editSubCategory}
        onClose={() => {
          setEditSubCategory(false);
          handleClose();
        }}
      >
        <AddSubCategory
          label="Edit Sub-Category"
          setEditSubCategory={setEditSubCategory}
          handleClose={handleClose}
          data={selectedRow}
          categories={categories}
        />
      </Dialog>

      <Dialog
        open={deleteSubCategory}
        onClose={() => {
          setDeleteSubCategory(false);
          handleClose();
        }}
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this Sub-Category?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteSubCategory(false);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              // setDeleteSubCategory(false);
              handleDeleteCategory()
              // handleClose();
              // Add your delete logic here
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubCategoryContent;
