import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, Dialog } from "@mui/material";
import AddSubCategory from "../../AddContainer/AddSubCategory";
import AdminContent from "../../AdminContent";
import { handleGetData } from "../../../../utils";
import SubCategoryContent from "../../AdminContent/SubCategoryContent";
import {
  setCategories,
  setSubcategories,
} from "../../../../store/slices/categorySlice";
import { useDispatch, useSelector } from "react-redux";

function SubCategories() {
  const dispatch = useDispatch();
  const [addSubCategoryOpen, setAddSubCategoryOpen] = useState(false);
  const { page, tableData, setTableData } = useOutletContext();
  const categories = useSelector((state) => state.categories.categories);
  console.log("categoris in subcategores from store", categories);
  const [pageTableData, setPageTableData] = useState([]);
  // console.log("page in subcategory is", page)
  const handleAddSubCategoryToggle = () => {
    setAddSubCategoryOpen((prev) => !prev);
  };
  async function callgetData() {
    const categoryData = await handleGetData("subcategories");
    setPageTableData(categoryData);
    dispatch(setSubcategories(categoryData));
  }
  useEffect(() => {
    callgetData();
  }, []);

  // Trigger data fetch when the dialog closes
  useEffect(() => {
    if (!addSubCategoryOpen) {
      callgetData();
    }
  }, [addSubCategoryOpen]);

  async function callgetCatData() {
    const categoryData = await handleGetData("categories");
    dispatch(setCategories(categoryData));
  }

  useEffect(() => {
    callgetCatData();
  }, []);

  return (
    <div>
      <div className="w-full flex justify-end px-4">
        <Button onClick={handleAddSubCategoryToggle} variant="contained">
          Add Sub-Category
        </Button>
      </div>
      <SubCategoryContent
        page={"Sub-Category"}
        tableData={pageTableData}
        categories={categories}
      />
      {/* **********Add Category Dialog*********** */}
      <Dialog open={addSubCategoryOpen} onClose={handleAddSubCategoryToggle}>
        <AddSubCategory
          handleAddSubCategoryToggle={handleAddSubCategoryToggle}
          setTableData={setTableData}
          categories={categories}
        />
      </Dialog>
    </div>
  );
}

export default SubCategories;
