
import React, { useState, useEffect } from 'react';
import UserNavbar from '../../components/User/UserNavbar';
import UserHomeContainer from '../../components/User/UserHomeContainer';
import ScrollCategories from '../../components/User/ScrollCategories';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAdvData, setCatAndSubData, setCategoryData, setInsightsData } from '../../store/slices/UserJourneyDataSlice';
import { addToCart } from '../../store/slices/UserDetailsSlice';

function HomePage() {
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    if (!hasSeenPopup) {
      setOpenPopup(true);
      localStorage.setItem('hasSeenPopup', 'true');
    }
    // const cartItemsInLocalStorage = JSON.parse(localStorage.getItem("cartItems")) || [];
    // console.log("items in localstorage? ", cartItemsInLocalStorage)
    // if(cartItemsInLocalStorage){
    //   dispatch(addToCart(...cartItemsInLocalStorage));
    // }
  }, []);

  const handleClose = () => {
    setOpenPopup(false);
  };

  useEffect(()=>{
    const getUserCategoriesData = async () => {
      try {
        const response = await axios.get('http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/api/categories/')
        // console.log("user category data", response?.data)
        dispatch( setCategoryData(response?.data))
      } catch (error) {
        console.log("error while getting user category data", error?.response?.message)
      }
    }
    getUserCategoriesData()

    const getCatAndSubData = async () => {
      try {
        const response = await axios.get('http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/api/advertisements/')
        // console.log("cat and subcat data", response?.data)
        dispatch( setAdvData(response?.data))
      } catch (error) {
        console.log("error while getting adv data", error?.response?.message)
      }
    }
    getCatAndSubData()

    const getInsightsData = async ()=>{
      try {
        const respone = await axios.get("http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/insights/")
        console.log("insights data", respone?.data)
        dispatch(setInsightsData(respone?.data))
      } catch (error) {
        console.log("error while getting user category data", error?.response?.message)
      }
    }
    getInsightsData()
  },[])



  return (
    <div>
      <UserHomeContainer />
      {/* <ScrollCategories /> */}
      
      <Dialog
        open={openPopup}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            border: 'none', // No border
            borderRadius: '0px', // Remove border radius if you want sharp edges
          },
        }}
      >
        <DialogContent className="relative p-0">
          <IconButton
            onClick={handleClose}
            className="absolute top-2 right-2 text-gray-500"
          >
            <CloseIcon />
          </IconButton>
          <img
            src="https://via.placeholder.com/600x400" // Replace with your image URL
            alt="Popup Image"
            className="w-full h-auto"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default HomePage;
