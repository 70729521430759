

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Select from "react-select";
import { centralizedApi } from "../../../services/API/centralizedApi";
import { toastError, toastSuccess } from "../../../utils/toastUtil";

function AddSubCategory({
  label,
  setEditSubCategory,
  handleClose,
  data,
  handleAddSubCategoryToggle,
  categories,
}) {
  console.log("data in addsub cat is",data)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      subCategoryName: "",
      subCategoryDescription: "",
      discount: 0,
      category: null,
    },
  });

  const [imagePreview, setImagePreview] = useState(null);
  const imageFile = watch("image");

  useEffect(() => {
    // Populate category options
    console.log('categories in use Effect while editing', categories)
    const manipulateExistingCat = categories?.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setValue("categoryOptions", manipulateExistingCat);
    console.log("manipuate find dat out", manipulateExistingCat)
    // Pre-fill form fields if in edit mode
    if (data?.original) {
      const { name, details, discount, category, image } = data.original;
      setValue("subCategoryName", name || "");
      setValue("subCategoryDescription", details || "");
      setValue("discount", discount || 0);
      console.log("manipuate find dat", manipulateExistingCat)
      const selectedCategory = manipulateExistingCat.find(
        (cat) => cat.label === category
      );
      setValue("category", selectedCategory || null);

      if (image) {
        setImagePreview(image);
      } else {
        setImagePreview(null);
      }
    }
  }, [categories, data, setValue]);

  useEffect(() => {
    if (imageFile && imageFile.length > 0) {
      const file = imageFile[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  }, [imageFile]);

  const onSubmit = async (formData) => {
    console.log("formdata on add sub cat", formData)
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.subCategoryName);
    formDataToSend.append("details", formData.subCategoryDescription);
    formDataToSend.append("discount", formData.discount);
    formDataToSend.append("category", formData.category.label);

    if (imageFile && imageFile.length > 0) {
      formDataToSend.append("image", imageFile[0]);
    }

    try {
      let response;
      if (label) {
        response = await centralizedApi.editSubCategory(data?.original?.id, formDataToSend);
      } else {
        response = await centralizedApi.createSubCategory(formDataToSend);
      }

      if (response?.id || response?.statuscode === 201) {
        toastSuccess(`${response?.id ? "Sub-Category Updated" : "Sub-Category Added"} Successfully.`);
        if (setEditSubCategory) {
          setEditSubCategory(false);
          handleClose();
          window.location.reload();
        } else {
          handleAddSubCategoryToggle();
        }
      } else {
        toastError("Unexpected status code: " + response?.statuscode);
      }
    } catch (error) {
      console.error("Error details:", error);
      toastError("Error while adding sub-category.");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>{label || "Add Sub-Category"}</DialogTitle>
      <DialogContent>
        <Controller
          name="category"
          control={control}
          rules={{ required: "Category is required" }}
          render={({ field }) => (
            <Select
              {...field}
              options={watch("categoryOptions")}
              placeholder="Category"
              styles={{ container: (provided) => ({ ...provided, width: "100%" }) }}
              value={field.value}
              onChange={(selectedOption) => field.onChange(selectedOption)}
            />
          )}
        />
        {errors.category && <p style={{ color: "red" }}>{errors.category.message}</p>}

        <TextField
          sx={{ width: "100%", marginTop: ".5rem" }}
          placeholder="Sub-Category Name"
          {...register("subCategoryName", { required: "Sub-Category Name is required" })}
          error={!!errors.subCategoryName}
          helperText={errors.subCategoryName?.message}
        />

        <TextField
          sx={{ width: "100%", marginTop: ".5rem" }}
          placeholder="Sub-Category Description"
          {...register("subCategoryDescription", { required: "Sub-Category Description is required" })}
          error={!!errors.subCategoryDescription}
          helperText={errors.subCategoryDescription?.message}
        />

        <TextField
          sx={{ width: "100%", marginTop: ".5rem" }}
          placeholder="Discount"
          type="number"
          {...register("discount", {
            required: "Discount is required",
            valueAsNumber: true,
          })}
          error={!!errors.discount}
          helperText={errors.discount?.message}
          label="Discount"
        />

        {imagePreview && (
          <Box sx={{ marginTop: ".5rem" }}>
            <img
              src={imagePreview}
              alt="Sub-Category"
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          </Box>
        )}

        <input
          type="file"
          accept="image/*"
          {...register("image")}
          style={{ marginTop: ".5rem" }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" type="submit">
          {label || "Add Sub-Category"}
        </Button>
      </DialogActions>
    </Box>
  );
}

export default AddSubCategory;

