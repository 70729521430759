import React, { createContext, useState, useContext } from 'react';

// Create Context
const SharedContext = createContext();

// Create a Provider Component
export const ContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <SharedContext.Provider value={{ loading, setLoading }}>
      {children}
    </SharedContext.Provider>
  );
};

// Custom Hook for using the context
export const useSharedContext = () => useContext(SharedContext);
