import React from 'react';
import { Navigate } from 'react-router-dom';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const AdminRoute = ({ children }) => {
  const adminToken = getCookie('adminToken');
  const adminSessionId = getCookie('adminSessionId');
  console.log("yes i am here")
  if (!adminToken || !adminSessionId) {
    return <Navigate to="/admin-login" />;
  }

  return children;
};

export default AdminRoute;
