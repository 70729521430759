import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#f0f4f8',
  textAlign: 'center',
});

const Title = styled('h1')({
  fontSize: '96px',
  marginBottom: '20px',
  color: '#333',
});

const Subtitle = styled('p')({
  fontSize: '24px',
  marginBottom: '40px',
  color: '#666',
});

const StyledButton = styled(Button)({
  backgroundColor: '#1976d2',
  color: '#fff',
  padding: '10px 20px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#1565c0',
  },
});

const NotFound = () => {
  return (
    <Container>
      <Title>404</Title>
      <Subtitle>Page Not Found</Subtitle>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <StyledButton variant="contained">
          Go Back Home
        </StyledButton>
      </Link>
    </Container>
  );
};

export default NotFound;
