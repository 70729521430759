import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Button, MenuItem } from '@mui/material';
import { toastError, toastSuccess } from '../../utils/toastUtil';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { logoutAffiliate } from '../../utils/userUtils';


const SUPPORTED_FORMATS = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

// Validation schema using yup
const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  
  mobile: yup
  .string()
  .matches(/^[6-9][0-9]{9}$/, 'Mobile number must start with 6, 7, 8, or 9 and be exactly 10 digits')
  .required('Mobile number is required'),

  age: yup
    .number()
    .required('Age is required')
    .positive('Age must be a positive number')
    .integer('Age must be an integer')
    .min(18, 'Age must be at least 18')
    .max(100, 'Age must be less than or equal to 100'),

  address: yup.string().required('Address is required'),
  
  document_type: yup.string().required('Document type is required'),

  document_upload: yup
    .mixed()
    .required('Document upload is required')
    .test('fileType', 'Unsupported file format. Only pdf, jpg, jpeg, png are allowed', (value) => {
      return value && value[0] && SUPPORTED_FORMATS.includes(value[0].type);
    }),

  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),

  roa: yup.string(),

  status: yup.string(),
});


function Affiliate() {
  const navigate = useNavigate()
  const [documentPreview, setDocumentPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // React Hook Form with validation
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm({
    resolver: yupResolver(schema),
  });

  // Watch for document upload to show preview
  const documentUpload = watch('document_upload');

  // Submit handler
  const onSubmit = async (data) => {
    
    const formData = new FormData();

    // Append form data except document upload
    Object.keys(data).forEach((key) => {
      if (key !== 'document_upload') {
        formData.append(key, data[key]);
      }
    });

    // Append the uploaded document (first file from FileList)
    if (documentUpload && documentUpload.length > 0) {
      formData.append('document_upload', documentUpload[0]);  // This appends the correct file
    }
    setIsLoading(true)
    let toastId = toast.loading('Please wait...');
    try {
      const response = await fetch('http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/affiliate/create/', {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();

      setIsLoading(false)
      toast.dismiss(toastId)
      if (result?.uid) {
        toastSuccess('Affiliate created successfully');
        reset(); // Resets the form
        setDocumentPreview(null); // Clears the document preview
        // sessionStorage.removeItem('affiliateAccess', 'affiliateRefresh', 'affiliate_username');
        logoutAffiliate(navigate)
      } else {
        throw new Error('Submission failed');
      }
      console.log(result);
    } catch (error) {
      setIsLoading(false)
      toast.dismiss(toastId)
      console.error('Error submitting form', error);
      toastError('Error while creating affiliate');
    }
  };

  // Update document preview on file change
  React.useEffect(() => {
    if (documentUpload && documentUpload.length > 0) {
      const file = documentUpload[0];
      setDocumentPreview(URL.createObjectURL(file));
    } else {
      setDocumentPreview(null); // Clear preview if no file is selected
    }
  }, [documentUpload]);

  // Check if the form is ready to submit
  const isSubmitDisabled = !(
    watch('name') &&
    watch('mobile') &&
    watch('age') &&
    watch('address') &&
    watch('document_type') &&
    watch('document_upload') &&
    watch('email')
  );

  return (
    <div className="p-6 md:p-12 bg-gray-100 rounded-lg shadow-md max-w-lg mx-auto">
      <h2 className="text-2xl font-semibold text-center mb-6">Affiliate Registration</h2>
      
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        {/* Name */}
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          {...register('name')}
          error={!!errors.name}
          helperText={errors.name?.message}
        />

        {/* Mobile */}
        <TextField
          label="Mobile"
          variant="outlined"
          fullWidth
          {...register('mobile')}
          error={!!errors.mobile}
          helperText={errors.mobile?.message}
        />

        {/* Age */}
        <TextField
          label="Age"
          type="number"
          variant="outlined"
          fullWidth
          {...register('age')}
          error={!!errors.age}
          helperText={errors.age?.message}
        />

        {/* Address */}
        <TextField
          label="Address"
          variant="outlined"
          fullWidth
          {...register('address')}
          error={!!errors.address}
          helperText={errors.address?.message}
        />

        {/* Document Type */}
        <TextField
          label="Document Type"
          select
          variant="outlined"
          fullWidth
          {...register('document_type')}
          error={!!errors.document_type}
          helperText={errors.document_type?.message}
        >
          <MenuItem value="passport">Passport</MenuItem>
          <MenuItem value="id_card">ID Card</MenuItem>
          <MenuItem value="driver_license">Driver's License</MenuItem>
        </TextField>

        {/* Document Upload */}
        <div>
          <label className="block text-gray-700">Document Upload</label>
          <input
            type="file"
            accept=".pdf,.jpg,.jpeg,.png"
            {...register('document_upload')}
            className="block w-full text-sm text-gray-500"
          />
          {errors.document_upload && (
            <span className="text-red-500 text-sm">{errors.document_upload?.message}</span>
          )}
        </div>

        {/* Document Preview */}
        {documentPreview && (
          <div className="mt-4">
            <img src={documentPreview} alt="Document Preview" className="h-24 w-24 object-cover" />
          </div>
        )}

        {/* Email */}
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          {...register('email')}
          error={!!errors.email}
          helperText={errors.email?.message}
        />

        {/* ROA */}
        <TextField
          label="ROA (optional)"
          variant="outlined"
          fullWidth
          {...register('roa')}
        />

        {/* Status */}
        <TextField
          label="Status (optional)"
          variant="outlined"
          fullWidth
          {...register('status')}
        />

        {/* Submit button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isSubmitDisabled || isLoading}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

export default Affiliate;
