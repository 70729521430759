import React, { useState } from "react";
import { Typography, Card, CardContent, IconButton, Tooltip } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ProductModal from "../ProductModal";
import { useNavigate } from "react-router-dom";
import Image from "../../GlobalComponents/Image";
import "./TrendingProducts.css";

function TrendingProducts({ Text, insightsData }) {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProduct(null);
  };

  return (
    <div className="p-4">
      <Typography
        variant="h6"
        sx={{ textAlign: "left", paddingLeft: "1.2rem", marginBottom: "1rem" }}
      >
        {Text}
      </Typography>

      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {insightsData?.map((product, index) => (
          <div key={index} className="relative">
            <Card
              className="product-item shadow-xl hover:shadow-2xl transition-shadow cursor-pointer"
              onClick={() =>
                navigate(
                  `/category/${product?.category_id}/subcategory/${product?.subcategory_id}/product/${product?.id}`
                )
              }
            >
              <Image
                alt={product.name}
                src={product?.images?.[0]}
                className="product-image"
                styles={{objectFit:'contain'}}
              />
              <CardContent className="p-0" style={{padding:'0px'}} >
                <Typography
                  textAlign={"left"}
                  variant="subtitle1"
                  className="product-name font-bold "
                  style={{textAlign:'center',fontWeight:'bold'}}
                >
                  {product.name}
                </Typography>
                <Typography
                  textAlign={"left"}
                  variant="subtitle1"
                  className="product-name font-bold"
                  style={{   
                    boxShadow: '0 0 2px 2px lightgray',
                    borderRadius: "35px",
                    display: "inline-block",
                    padding: "4px 12px",
                    fontSize: "17px",
                    fontWeight: "450",
                    /* margin-top: 17px; */
                    background: "#dcdcdc "}}
                >
                  ₹ {product.price}
                </Typography>
                <Tooltip title="Quick View" placement="top">
                <div className="absolute top-2 right-2 bg-gray-400 rounded-full">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenModal(product);
                    }}
                  >
                    <ZoomInIcon sx={{ color: "white" }} />
                  </IconButton>
                </div>
                </Tooltip>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>

      {selectedProduct && (
        <ProductModal
          open={openModal}
          onClose={handleCloseModal}
          product={selectedProduct}
        />
      )}
    </div>
  );
}

export default TrendingProducts;
