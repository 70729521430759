
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { centralizedApi } from "../../../services/API/centralizedApi";
import { toastError, toastSuccess } from "../../../utils/toastUtil";
import { callgetData } from "../../../services/API/admin";

function AddCategory({ label, data, setEditCategory, handleClose,handleAddCategoryToggle,setTableData }) {
  console.log('settabledata is', setTableData)
  const [imagePreview, setImagePreview] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      details: "", // Change defaultValues key from "description" to "details"
      discount: 0,
      image: null,
    },
  });
  const imageFile = watch("image");

  useEffect(() => {
    if (data?.original) {
      const { name, details, discount, image } = data.original;
      if (image) {
        console.log("Setting image preview:", image);
        setImagePreview(image);
      } else {
        setImagePreview(null);
      }
      setValue("name", name || "");
      setValue("details", details || "");
      setValue("discount", discount || 0);
    }
  }, [data, setValue]);

  useEffect(() => {
    if (imageFile && imageFile.length > 0) {
      const file = imageFile[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  }, [imageFile]);

  const onSubmit = async (formData) => {
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("details", formData.details);
    formDataToSend.append("discount", formData.discount);

    if (imageFile && imageFile.length > 0) {
      formDataToSend.append("image", imageFile[0]);
    }

    try {
      let response;
      if (label) {
        response = await centralizedApi.editCategory(data?.original?.id, formDataToSend);
      } else {
        response = await centralizedApi.createCategory(formDataToSend);
      }

      console.log("API Response:", response);

      if (response?.id || response?.statuscode === 201) {

        toastSuccess(`${response?.id ? "Category Updated":"Category Added"} Successfully.`);
        if(setEditCategory){

          setEditCategory(false);
          handleClose();
          window.location.reload()
        }else{
          handleAddCategoryToggle()
        }
      } else {
        // Handle other status codes
        toastError("Unexpected status code: " + response?.statuscode);
      }
    } catch (error) {
      console.error("Error details:", error);
      toastError("Error while adding category.");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>{label || "Add Category"}</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ width: "100%", marginTop: ".5rem" }}
          placeholder="Category Name"
          {...register("name", { required: "Category Name is required" })}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          sx={{ width: "100%", marginTop: ".5rem" }}
          placeholder="Category Description"
          {...register("details", {
            required: "Category Description is required",
          })}
          error={!!errors.details}
          helperText={errors.details?.message}
          label="Description"
        />
        <TextField
          sx={{ width: "100%", marginTop: ".5rem" }}
          placeholder="Discount"
          type="number"
          {...register("discount", {
            required: "Discount is required",
            valueAsNumber: true,
          })}
          error={!!errors.discount}
          helperText={errors.discount?.message}
          label="Discount"
        />

        {imagePreview && (
          <Box sx={{ marginTop: ".5rem" }}>
            <img
              src={imagePreview}
              alt="Category"
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          </Box>
        )}

        <input
          type="file"
          accept="image/*"
          {...register("image")}
          style={{ marginTop: ".5rem" }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" type="submit">
          {label || "Add Category"}
        </Button>
      </DialogActions>
    </Box>
  );
}

export default AddCategory;
