import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCategory from "../AddContainer/AddCategory";
import AddProduct from "../AddContainer/AddProduct";
import { centralizedApi } from "../../../services/API/centralizedApi";
import { toastError, toastSuccess } from "../../../utils/toastUtil";

const ProductsContent = ({ page, tableData, subcategories }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editProduct, setEditProduct] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(false);
  console.log('selected row in items is....', selectedRow)
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    console.log("Edit:", selectedRow);
    setEditProduct(true);
    // handleClose();
  };

  const handleDelete = () => {
    console.log("Delete:", selectedRow);
    setDeleteProduct(true);
    // handleClose();
  };
  const handleDeleteProduct = async ()=>{
    try {
      const response = await centralizedApi.deleteItem(selectedRow?.original?.id)
      console.log("response from delete product", response);
      setDeleteProduct(false);
      handleClose();
      // tableData.mutate()
      toastSuccess("Product Deleted Successfully");
      // callgetData();
      window.location.reload()
      // if (response?.status === 204) {
      // }
    } catch (error) {
      console.log("error while deleting product", error);
      toastError("Error while deleting product");
      setDeleteProduct(false);
      handleClose();
    }
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "id", // Assuming id is required
        header: "ID",
        size: 80,
      },
      {
        accessorKey: "name", // Name of the item (category, product, etc.)
        header: "Name",
        size: 150,
      },
      {
        accessorKey: "image",
        header: "Image",
        size: 150,
        Cell: ({ cell }) => (
          <img
            src={cell.getValue()}
            alt="Item"
            style={{ width: 50, height: 50 }}
          />
        ),
      },
      {
        accessorKey: "details", // Details about the item
        header: "Details",
        size: 200,
      },
      {
        accessorKey: "discount", // Discount if applicable
        header: "Discount",
        size: 100,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleClick(event, row)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEdit}>
                <EditIcon sx={{ marginRight: "0.5rem" }} /> Edit
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <DeleteIcon sx={{ marginRight: "0.5rem" }} /> Delete
              </MenuItem>
            </Menu>
          </Box>
        ),
      },
    ],
    [anchorEl]
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData, // Use the dynamic tableData
  });

  const pageName =
    page === "Category"
      ? "Categories"
      : page === "Sub-Category"
      ? "Sub-Categories"
      : page === "Product"
      ? "Products"
      : page === "Orders"
      ? "Orders"
      : "Images";

  return (
    <Box sx={{ margin: "1rem" }}>
      <Typography
        variant="h4"
        sx={{ textAlign: "left", margin: "1rem 0" }}
        className="text-primary"
      >
        {pageName}
      </Typography>
      {pageName !== "Images" && (
        <MaterialReactTable table={table} style={{ margin: "2rem" }} />
      )}
      {pageName === "Images" && (
        <Box>
          <Button variant="contained">Add Advertisement</Button>
          <Button variant="contained">Add Banner</Button>
        </Box>
      )}

      <Dialog
        open={editProduct}
        onClose={() => {
          setEditProduct(false);
          handleClose();
        }}
      >
        <AddProduct
          label="Edit Sub-Category"
          setEditProduct={setEditProduct}
          handleClose={handleClose}
          data={selectedRow?.original}
          subcategories={subcategories}
        />
      </Dialog>
      <Dialog
        open={deleteProduct}
        onClose={() => {
          setDeleteProduct(false);
          handleClose();
        }}
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this Product?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteProduct(false);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              // setDeleteProduct(false);
              // handleClose();
              handleDeleteProduct()
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductsContent;
