// components/UserLayout.js
import React from "react";
import { Outlet } from "react-router-dom";
import UserNavbar from "../UserNavbar";
import UserFooter from "../UserFooter";
// import UserNavbar from "../Navbar";
const UserLayout = () => {

  return (
    <>
      <UserNavbar />
      {/* Outlet renders the child route elements */}
      <div className="content min-h-screen">
        <Outlet />
      </div>
      <UserFooter />
    </>
  );
};

export default UserLayout;
