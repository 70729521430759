import React from 'react'
import AdminContent from '../../AdminContent'
import { useOutletContext } from 'react-router-dom';

function Orders() {
  const { page, tableData, setTableData } = useOutletContext();
  return (
    <div>
      Orders
      {/* <AdminContent page={page} tableData={tableData}/> */}
    </div>
  )
}

export default Orders;