// import React, { useState } from 'react';
// import { useForm, Controller } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
// import {
//   Button,
//   Typography,
//   DialogActions,
//   DialogContent,
//   DialogTitle
// } from '@mui/material';

// const advertiseSchema = yup.object().shape({
//   banner: yup.mixed()
//     .required('Banner image is required')
//     .test('fileSize', 'Banner image file is too large', (value) =>
//       value && value[0] && value[0].size <= 5000000 // 5MB limit
//     )
//     .test('fileType', 'Unsupported file format', (value) =>
//       value && value[0] && ['image/jpeg', 'image/png', 'image/jpg'].includes(value[0].type)
//     ),
  
//   advertisement: yup.mixed()
//     .required('Advertisement image is required')
//     .test('fileSize', 'Advertisement image file is too large', (value) =>
//       value && value[0] && value[0].size <= 5000000 // 5MB limit
//     )
//     .test('fileType', 'Unsupported file format', (value) =>
//       value && value[0] && ['image/jpeg', 'image/png', 'image/jpg'].includes(value[0].type)
//     )
// });

// function AddAdvertise() {
//   const [bannerPreview, setBannerPreview] = useState(null);
//   const [adPreview, setAdPreview] = useState(null);

//   const { handleSubmit, control, formState: { errors } } = useForm({
//     resolver: yupResolver(advertiseSchema)
//   });

//   const onSubmit = async (data) => {
//     console.log('Form Data', data);
//     // Handle form submission logic here
//   };

//   const handleFileChange = (e, setPreview) => {
//     const file = e.target.files[0];
//     if (file) {
//       const previewUrl = URL.createObjectURL(file);
//       setPreview(previewUrl);
//     }
//   };

//   return (
//     <>
//       <DialogTitle className="text-lg font-semibold mb-4">Add Advertisement</DialogTitle>
//       <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
//         <DialogContent
//           className="flex flex-col items-center p-4 space-y-4"
//         >
//           <Controller
//             name="banner"
//             control={control}
//             render={({ field }) => (
//               <div className="w-full">
//                 <Typography className="text-base font-medium mb-2">Upload Banner Image</Typography>
//                 <input
//                   type="file"
//                   accept="image/png, image/jpeg, image/jpg"
//                   {...field}
//                   onChange={(e) => {
//                     handleFileChange(e, setBannerPreview);
//                     field.onChange(e.target.files);
//                   }}
//                   className="block w-full text-sm file:mr-4 file:py-2 file:px-4 file:rounded-md file:border file:border-gray-300 file:bg-gray-50 file:text-gray-700 file:cursor-pointer"
//                 />
//                 {errors.banner && (
//                   <Typography className="text-red-600 mt-2">{errors.banner.message}</Typography>
//                 )}
//                 {bannerPreview && (
//                   <img src={bannerPreview} alt="Banner Preview" className="mt-4 w-full max-w-xs rounded-md border border-gray-300" />
//                 )}
//               </div>
//             )}
//           />

//           <Controller
//             name="advertisement"
//             control={control}
//             render={({ field }) => (
//               <div className="w-full">
//                 <Typography className="text-base font-medium mb-2">Upload Advertisement Image</Typography>
//                 <input
//                   type="file"
//                   accept="image/png, image/jpeg, image/jpg"
//                   {...field}
//                   onChange={(e) => {
//                     handleFileChange(e, setAdPreview);
//                     field.onChange(e.target.files);
//                   }}
//                   className="block w-full text-sm file:mr-4 file:py-2 file:px-4 file:rounded-md file:border file:border-gray-300 file:bg-gray-50 file:text-gray-700 file:cursor-pointer"
//                 />
//                 {errors.advertisement && (
//                   <Typography className="text-red-600 mt-2">{errors.advertisement.message}</Typography>
//                 )}
//                 {adPreview && (
//                   <img src={adPreview} alt="Advertisement Preview" className="mt-4 w-full max-w-xs rounded-md border border-gray-300" />
//                 )}
//               </div>
//             )}
//           />
//         </DialogContent>
//         <DialogActions className="p-4">
//           <Button type="submit" variant="contained" color="primary" className="w-full">
//             Submit
//           </Button>
//         </DialogActions>
//       </form>
//     </>
//   );
// }

// export default AddAdvertise;
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { adminApi } from '../../../../services/API/admin';
import { toastError, toastSuccess } from '../../../../utils/toastUtil';

function AddAdvertise() {
  const [bannerPreview, setBannerPreview] = useState(null);
  const [adPreview, setAdPreview] = useState(null);
  const [existingBannerUrl, setExistingBannerUrl] = useState(null);
  const [existingAdUrl, setExistingAdUrl] = useState(null);

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    const fetchAdvertiseData = async () => {
      try {
        const response = await adminApi.getAdvertiseInfo(); // Replace with actual API endpoint
        if (response?.status === 'success') {
          const data = response.data;
          setExistingBannerUrl(data?.bannerUrl || '');
          setExistingAdUrl(data?.advertisementUrl || '');
          setBannerPreview(data?.bannerUrl || '');
          setAdPreview(data?.advertisementUrl || '');
        }
      } catch (error) {
        toastError('Failed to fetch advertisement info');
      }
    };

    fetchAdvertiseData();
  }, []);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      if (data.banner[0]) formData.append('banner', data.banner[0]);
      if (data.advertisement[0]) formData.append('advertisement', data.advertisement[0]);

      const response = await adminApi.postAdvertiseInfo(formData); // Replace with actual API endpoint
      if (response.status === 'success') {
        toastSuccess('Advertisement updated successfully');
      }
    } catch (error) {
      toastError('Failed to update advertisement');
    }
  };

  const handleFileChange = (e, setPreview) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
    }
  };

  return (
    <>
      <DialogTitle className="text-lg font-semibold mb-4">Add Advertisement</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <DialogContent className="flex flex-col items-center p-4 space-y-4">
          <Controller
            name="banner"
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <Typography className="text-base font-medium mb-2">Upload Banner Image</Typography>
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => {
                    handleFileChange(e, setBannerPreview);
                    field.onChange(e.target.files);
                  }}
                  className="block w-full text-sm file:mr-4 file:py-2 file:px-4 file:rounded-md file:border file:border-gray-300 file:bg-gray-50 file:text-gray-700 file:cursor-pointer"
                />
                {bannerPreview && (
                  <img src={bannerPreview} alt="Banner Preview" className="mt-4 w-full max-w-xs rounded-md border border-gray-300" />
                )}
                {existingBannerUrl && !bannerPreview && (
                  <img src={existingBannerUrl} alt="Existing Banner" className="mt-4 w-full max-w-xs rounded-md border border-gray-300" />
                )}
              </div>
            )}
          />

          <Controller
            name="advertisement"
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <Typography className="text-base font-medium mb-2">Upload Advertisement Image</Typography>
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => {
                    handleFileChange(e, setAdPreview);
                    field.onChange(e.target.files);
                  }}
                  className="block w-full text-sm file:mr-4 file:py-2 file:px-4 file:rounded-md file:border file:border-gray-300 file:bg-gray-50 file:text-gray-700 file:cursor-pointer"
                />
                {adPreview && (
                  <img src={adPreview} alt="Advertisement Preview" className="mt-4 w-full max-w-xs rounded-md border border-gray-300" />
                )}
                {existingAdUrl && !adPreview && (
                  <img src={existingAdUrl} alt="Existing Advertisement" className="mt-4 w-full max-w-xs rounded-md border border-gray-300" />
                )}
              </div>
            )}
          />
        </DialogContent>
        <DialogActions className="p-4">
          <Button type="submit" variant="contained" color="primary" className="w-full">
            Submit
          </Button>
        </DialogActions>
      </form>
    </>
  );
}

export default AddAdvertise;
