import * as yup from "yup";
export const signupLoginSchema = yup.object().shape({
    images: yup
      .mixed()
      .test("fileCount", "You can only upload 3 images", (files) => {
        if (!files || files.length === 0) return true;
        return files.length <= 3;
      })
      .test("fileType", "Only png, jpg, jpeg formats are allowed", (files) => {
        if (!files || files.length === 0) return true;
        return Array.from(files).every((file) =>
          ["image/png", "image/jpeg", "image/jpg"].includes(file.type)
        );
      }),
    video: yup
      .mixed()
      .test("fileCount", "You can only upload 1 video", (file) => {
        if (!file) return true;
        return file.length === 1;
      })
      .test("fileType", "Only mp4 format is allowed", (file) => {
        if (!file || file.length === 0) return true;
        return file[0]?.type === "video/mp4";
      }),
  });



  export const productSchema = yup.object().shape({
    productName: yup.string().required("Product name is required"),
    itemsInStock: yup
      .number()
      .required("Items in stock is required")
      .min(0, "Stock cannot be negative"),
    productPrice: yup
      .number()
      .required("Product price is required")
      .min(0, "Price cannot be negative"),
    discount: yup.number().min(0, "Discount cannot be negative"),
    subCategory: yup.object().shape({
      value: yup.string().required("Sub-category is required"),
    }).required("Sub-category is required"),
    SKU: yup.string().required("SKU is required"),
    specification: yup.string().required("Specification is required"),
    specialItemStatus: yup.boolean().required("Special item status is required"),
    token: yup
      .number()
      .required("Token is required")
      .positive("Token must be a positive number"),
    affiliateToken: yup
      .number()
      .required("Affiliate token is required")
      .positive("Affiliate token must be a positive number"),
    variantInput: yup.string(),
    images: yup
      .mixed()
      .test("fileSize", "You can only upload up to 3 images", (value) => {
        return !value || value.length <= 3;
      }),
    video: yup
      .mixed()
      .test("fileSize", "You can only upload one video", (value) => {
        return !value || value.length <= 1;
      }),
    variants: yup.array().of(
      yup.object().shape({
        color: yup.string().required("Color is required"),
        size: yup.string().required("Size is required"),
        price: yup.number().required("Price is required").min(0, "Price cannot be negative"),
        stock: yup.number().required("Stock is required").min(0, "Stock cannot be negative"),
      })
    ).min(1, "At least one variant is required"),
    tags: yup.array().of(yup.string()).min(1, "At least one tag is required"),
  });
  