import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Rating,
  Button,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import "./UserSubCategory.css";
import FilterSidebar from "../../FIlterSidebar";
import { useNavigate, useParams } from "react-router-dom";
import { setProductData } from "../../../../store/slices/UserJourneyDataSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ProductModal from "../../ProductModal";
import { handleAddToCart } from "../../../../utils/userUtils";

function UserSubCategory({ Text }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { catAndSubData, productData } = useSelector(
    (state) => state.userJourneyData
  );
  const { cartItems } = useSelector((state) => state.userDetailsData);
  const { categoryId, subCategoryId } = useParams();

  useEffect(() => {
    const getCatAndSubData = async () => {
      const findSubCatName = catAndSubData?.find(
        (category) => category.id === parseInt(categoryId)
      );
      const subcategory = findSubCatName?.subcategories?.find(
        (sub) => sub.id === parseInt(subCategoryId)
      );
      if (subcategory?.name) {
        try {
          const response = await axios.get(
            `http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/api/subfind/?q=${subcategory?.id}`
          );
          console.log("response in subcat", response?.data);
          dispatch(setProductData(response?.data));
        } catch (error) {
          console.log("Error while fetching data", error);
        }
      }
    };
    getCatAndSubData();

    return ()=>{dispatch(setProductData([]))}
  }, [categoryId]);

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProduct(null);
  };

  // const handleAddToCart = (product) => {
  //   dispatch(addToCart(product));
  //   let cartItemsInLocalStorage = JSON.parse(localStorage.getItem("cartItems"));
  //   if (!cartItemsInLocalStorage) {
  //     localStorage.setItem("cartItems", JSON.stringify([product]));
  //   } else {
  //     localStorage.setItem(
  //       "cartItems",
  //       JSON.stringify([...cartItemsInLocalStorage, product])
  //     );
  //   }
  //   toastSuccess("Item added to cart");
  // };

  const discountedPrice = (discount, price) => {
    return (price - price * (discount / 100)).toFixed(2);
  };

  return (
    <Box className="flex flex-col md:flex-row">
      <FilterSidebar />
      <Box flex={1} p={2}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "left",
            paddingLeft: "1.2rem",
            marginBottom: "1rem",
            fontSize: { xs: "1.2rem", md: "1.5rem" },
          }}
        >
          {Text}
        </Typography>

        <Grid container spacing={2}>
          {productData?.results?.map((product, index) => (
            <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{
                  maxWidth: { md: "280px" }, // Fix width for larger screens
                  height: { xs: "auto", md: "auto" }, // Auto height for mobile view
                  position: "relative",
                  margin: "auto", // Center cards for larger screens
                  paddingTop: "10px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `/category/${categoryId}/subcategory/${subCategoryId}/product/${product?.id}`
                  )
                }
              >
                <CardMedia
                  component="img"
                  image={product?.images?.[0] || "default-image.jpg"}
                  alt={product?.name}
                  sx={{
                    objectFit: "contain",
                    height: { xs: "100px", sm: "140px", md: "220px" },
                  }}
                />
                <CardContent
                  sx={{
                    padding: { xs: "0.8rem", sm: "1rem" },
                    textAlign: "left",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "0.85rem", sm: "1rem" },
                      fontWeight: "bold",
                    }}
                  >
                    {product.name}
                  </Typography>
                  <Rating
                    name="product-rating"
                    value={product.rating || 4}
                    readOnly
                    sx={{
                      fontSize: { xs: "0.8rem", sm: "1rem" },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "0.85rem", sm: "1rem" },
                    }}
                  >
                    {product?.discount ? (
                      <>
                      <span
                        style={{ textDecoration: "line-through", color: "red" }}
                      >
                        ₹{product.price}
                      </span>
                      <span style={{ color: "green", fontWeight:'500' }}>
                       &nbsp;&nbsp;₹{discountedPrice(product.discount, product.price)}
                    </span></>
                    ) : (
                      <span style={{ color: "green" }}>
                        ₹{discountedPrice(product.discount, product.price)}
                      </span>
                    )}
                  </Typography>
                  {product?.discount && (
                    <Typography
                      sx={{
                        fontSize: { xs: "0.85rem", sm: "1rem" },
                        color: "green",
                      }}
                    >
                      {product.discount}% Off
                    </Typography>
                  )}
                  <Box className="flex justify-between mt-2">
                    <Button
                      variant="contained"
                      fullWidth // Make button take full width on mobile
                      sx={{
                        fontSize: { xs: "0.7rem", sm: "0.8rem" },
                        padding: { xs: "0.3rem 0.6rem", sm: "0.4rem 1rem" },
                        width: "100%", // Button width 100%
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleAddToCart(product, dispatch,1, cartItems);
                      }}
                      // disabled // Disable button if out of stock
                      disabled={product.stock <= 0} // Disable button if out of stock
                    >
                      {/* Out of Stock */}
                      {product.stock > 0 ? "Add to Cart" : "Out of Stock"}
                    </Button>
                  </Box>
                </CardContent>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    background: "lightgray",
                    borderRadius: "50%",
                  }}
                >
                  <IconButton
                    sx={{
                      fontSize: {
                        xs: "1.02rem",
                        sm: "1.05rem",
                        color: "white",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenModal(product);
                    }}
                  >
                    <ZoomInIcon />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {selectedProduct && (
        <ProductModal
          open={openModal}
          onClose={handleCloseModal}
          product={selectedProduct}
        />
      )}
    </Box>
  );
}

export default UserSubCategory;
