import { configureStore } from '@reduxjs/toolkit';
import categoryReducer from './slices/categorySlice';
import UserJourneyDataSlice from './slices/UserJourneyDataSlice';
import UserDetailsSlice from './slices/UserDetailsSlice';

const store = configureStore({
  reducer: {
    categories: categoryReducer,
    userJourneyData: UserJourneyDataSlice,
    userDetailsData: UserDetailsSlice
    // You can add other reducers here if needed
  },
});

export default store;
