

import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Grid,
  Link,
  IconButton,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from 'react-hot-toast';
function AffiliateLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [resendTimer, setResendTimer] = useState(180); // 3 minutes
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const otpRefs = useRef([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => setResendTimer((prev) => prev - 1), 1000);
    } else {
      setIsResendEnabled(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  useEffect(() => {
    const token = sessionStorage.getItem("affiliateAccess");
    if (token) {
      navigate("/affiliate-dashboard");
    }
  }, []);

  const handleLogin = async (data) => {
    console.log("Login Data:", data);
    const { username, password } = data;
    setIsLoading(true)
    let toastId = toast.loading("Please wait...");
    try {
      
      const response = await axios.post(
        "http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/api/affiliate/login/",
        { username, password }
      );
      if (response?.data?.status === "OK") {
        sessionStorage.setItem("affiliateAccess", response?.data?.token);
        sessionStorage.setItem("affiliateRefresh", response?.data?.refresh_token);
        sessionStorage.setItem("affiliate_username", response?.data?.id);
        toast.dismiss(toastId)
        navigate("/affiliate-dashboard");
      }
    } catch (error) {
      toast.dismiss(toastId)
      toast.error("Invalid username or password");
    }finally{
      setIsLoading(false)
    }
  };

  const handleMobileSubmit = () => {
    // Validate mobile number and send OTP
    if (/^\d{10}$/.test(mobile)) {
      setIsOtpSent(true);
      setResendTimer(180);
      setIsResendEnabled(false);
    }
  };

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== "" && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleBackspace = (index) => {
    if (index > 0) {
      otpRefs.current[index - 1].focus();
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };

  const handleResendOtp = () => {
    setIsResendEnabled(false);
    setResendTimer(180);
    console.log("Resending OTP to:", mobile);
  };

  const isOtpComplete = otp.every((digit) => digit !== "");

  return (
    <>
      {!sessionStorage.getItem("affiliateAccess") && (
        <Container maxWidth="xs">
          <Box className="p-4 shadow-lg rounded-lg bg-white mt-10 relative">
            {isForgotPassword ? (
              <>
                <IconButton
                  onClick={() => setIsForgotPassword(false)}
                  className="absolute left-4 top-4"
                >
                  <ArrowBackIcon />
                </IconButton>

                <Typography variant="h5" align="center" gutterBottom>
                  Forgot Password
                </Typography>

                {!isOtpSent ? (
                  <>
                    <TextField
                      label="Mobile Number"
                      fullWidth
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      error={!!errors.mobile}
                      helperText={
                        errors.mobile ? "Enter a valid mobile number" : ""
                      }
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleMobileSubmit}
                      disabled={!/^\d{10}$/.test(mobile)}
                    >
                      Send OTP
                    </Button>
                  </>
                ) : (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography align="center" gutterBottom>
                        Enter the OTP sent to {mobile}
                      </Typography>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => {
                            setIsOtpSent(false);
                            setOtp(new Array(6).fill(""));
                            setMobile("");
                          }}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Box className="flex justify-center space-x-2">
                      {otp.map((value, index) => (
                        <TextField
                          key={index}
                          inputRef={(el) => (otpRefs.current[index] = el)}
                          value={value}
                          onChange={(e) =>
                            handleOtpChange(e.target.value, index)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Backspace" && !value)
                              handleBackspace(index);
                          }}
                          inputProps={{ maxLength: 1 }}
                          className="w-12"
                          variant="outlined"
                          margin="normal"
                        />
                      ))}
                    </Box>

                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => console.log("Verify OTP")}
                      disabled={!isOtpComplete} // Disable until OTP is fully entered
                      className="mt-2"
                    >
                      Verify
                    </Button>

                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleResendOtp}
                      disabled={!isResendEnabled}
                      style={{ marginTop: "1rem" }}
                    >
                      {isResendEnabled
                        ? "Resend OTP"
                        : `Resend OTP in: ${Math.floor(resendTimer / 60)}:${(
                            resendTimer % 60
                          )
                            .toString()
                            .padStart(2, "0")}`}
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                <Typography variant="h5" align="center" gutterBottom>
                  Affiliate Login
                </Typography>
                <form onSubmit={handleSubmit(handleLogin)}>
                  <TextField
                    label="Username"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    {...register("username", {
                      required: "Username is required",
                    })}
                    error={!!errors.username}
                    helperText={errors.username?.message}
                  />
                  <TextField
                    label="Password"
                    fullWidth
                    margin="normal"
                    type="password"
                    variant="outlined"
                    {...register("password", {
                      required: "Password is required",
                    })}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="mt-4"
                    disabled={isLoading}
                  >
                    Login
                  </Button>
                </form>

                <Grid container justifyContent="space-between" className="mt-4">
                  {/* <Grid item>
                    <Link
                      href="#"
                      variant="body2"
                      onClick={() => setIsForgotPassword(true)}
                    >
                      Forgot Password?
                    </Link>
                  </Grid> */}
                  <Grid item>
                    <Link
                      href="#"
                      variant="body2"
                      onClick={() => navigate("/affiliate-registration")}
                    >
                      Not an affiliate user? Register
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Container>
      )}
    </>
  );
}

export default AffiliateLogin;
