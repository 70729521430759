import { Box, Button } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import quirckart from '../../../assets/images/quirckart.png'
import { useNavigate } from "react-router-dom";

function AdminNav({ handleDrawerOpen }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove cookies by setting them to expire in the past with additional attributes
    document.cookie = "adminToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; SameSite=Strict";
    document.cookie = "adminSessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; SameSite=Strict";
    
    // Navigate to admin login page
    navigate("/admin-login");
  };
  

  return (
    <div
      style={{
        height: "7vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 2rem",
        boxShadow: "0 0 10px 1px gray",
        marginBottom: "1rem",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <MenuIcon onClick={handleDrawerOpen} sx={{ cursor: 'pointer' }} fontSize="large" />
        <img src={quirckart} style={{ width: '100px' }} alt="Logo" />
      </Box>
      <Button variant="contained" onClick={handleLogout}>Logout</Button>
    </div>
  );
}

export default AdminNav;
