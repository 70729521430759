import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  InputBase,
  CircularProgress,
  Typography,
  MenuItem,
  Select,
  Avatar,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import debounce from "lodash/debounce";
import axios from "axios";
import toast from "react-hot-toast";
import { toastError, toastSuccess } from "../../../utils/toastUtil";
import Close from "@mui/icons-material/Close";

function AffiliateSearchProduct({
  isLoading,
  setIsLoading,
  setToken,
  setOpenTokenModal,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  console.log("selectd product", selectedProduct);
  // Mock function to simulate API call
  const fetchProducts = async (query) => {
    setSearchLoading(true);
    // Simulate API call delay
    try {
      const response = await axios.get(
        `http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/items/search/?q=${query}`
      );
      if (response?.data?.length > 0) {
        console.log(response);
        setSearchResults(response?.data);
        setSearchLoading(false);
      } else {
        setSearchResults([]);
        setSearchLoading(false);
        toastError("No results found");
      }
    } catch (error) {
      console.log(error);
    }

    // }, 1000);
  };

  // Debounce search input
  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query) {
        fetchProducts(query);
      } else {
        setSearchResults([]);
      }
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setSearchQuery(""); // Clear search input
    setSearchResults([]); // Clear dropdown after selection
  };

  const generateAffiliateLink = async () => {
    setIsLoading(true);
    let toastId = toast.loading("Generating link...");
    try {
      const response = await axios.post(
        "http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/api/affiliate-links/",
        {
          affiliate_username: sessionStorage.getItem("affiliate_username"),
          item: selectedProduct?.id,
        }
      );
      if (response?.data?.link) {
        const originalUrl = response?.data?.link;

        // Extract the UID value
        const uidValue = originalUrl.split("/share")[1];

        // Remove slashes from the UID value
        const sanitizedUID = uidValue.replace(/\//g, "");

        // Get the current website's hostname and protocol
        const currentHost =
          window.location.protocol + "//" + window.location.hostname;

        // Construct the new URL with sanitized UID as a query parameter
        const newUrl = `${currentHost}/api/share/${sanitizedUID}`;
        setToken(newUrl);
        setOpenTokenModal(true);
        setIsLoading(false);
        let message =
          response?.data?.status?.slice(0, 1).toUpperCase() +
          response?.data?.status?.slice(1);
        toastSuccess(message);
        toast.dismiss(toastId);
      }
    } catch (error) {
      toast.dismiss(toastId);
      setIsLoading(false);
      toastError("Failed to generate link");
    }
  };

  const handleClearSelectedItem = () => {
    setSelectedProduct(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {/* Search Box */}
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Product"
          inputProps={{ "aria-label": "search product" }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>

      {/* Display search results */}
      {searchLoading ? (
        <CircularProgress sx={{ alignSelf: "center" }} />
      ) : (
        searchResults.length > 0 && (
          <Paper sx={{ maxHeight: "200px", overflowY: "auto", mt: 1 }}>
            {searchResults.map((product) => (
              <MenuItem
                key={product.id}
                onClick={() => handleProductSelect(product)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  p: 2,
                }}
              >
                <Avatar src={product?.images?.[0]} alt={product.name} />
                <Box>
                  <Typography variant="body1">{product.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {product.price}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Paper>
        )
        // :
        // searchQuery.length > 0 && !searchLoading &&
        // <Typography>No Results Found</Typography>
      )}

      {/* Display selected product details */}
      {selectedProduct && (
        <Box
          sx={{
            p: 2,
            mt: 2,
            border: "1px solid lightgray",
            borderRadius: 1,
            backgroundColor: "#f9f9f9",
            width: "100%",
            textAlign: "center",
            position: "relative",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Avatar
                src={selectedProduct?.images?.[0]}
                alt={selectedProduct.name}
                sx={{ width: 100, height: 100, margin: "0 auto" }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="h6">{selectedProduct.name}</Typography>
              <Typography variant="body1">{selectedProduct.price}</Typography>
              <Typography variant="body2" color="textSecondary">
                Token: {selectedProduct.token}
              </Typography>
            </Grid>
          </Grid>
          <IconButton
            onClick={() => {
              handleClearSelectedItem();
            }}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <Close />
          </IconButton>
        </Box>
      )}

      {/* Generate Link Button */}
      <Button
        variant="contained"
        fullWidth
        disabled={isLoading || !selectedProduct}
        onClick={generateAffiliateLink}
      >
        Generate Link
      </Button>
    </Box>
  );
}

export default AffiliateSearchProduct;
