import React from 'react';
import './CollectionCard.css';

const CollectionCard = () => {
    return (
        <div className="collection-container">
            <div className="collection-wrapper">
                {/* Container 1 */}
                <div className="card">
                    <h2 className="card-title">Festive essentials from Small Businesses</h2>
                    <div className="card-grid">
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/1.png" alt="Ethnic wear" />
                            <p>Up to 70% off | Ethnic wears</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/2.png" alt="Lights and lamps" />
                            <p>Up to 60% off | Lights & lamps</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/3.png" alt="Furnishings" />
                            <p>Up to 55% off | Furnishings</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/3.png" alt="Pooja essentials" />
                            <p>Up to 60% off | Pooja essentials</p>
                        </div>
                    </div>
                    <a href="#" className="card-link">See all offers</a>
                </div>

                {/* Container 2 */}
                <div className="card">
                    <h2 className="card-title">Products from artisans & women-owned brands</h2>
                    <div className="card-grid">
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/1.png" alt="Handcrafted items from artisans" />
                            <p>Handcrafted items from artisans</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/2.png" alt="Women-led businesses" />
                            <p>Items from women-led businesses</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/3.png" alt="Government emporium" />
                            <p>Government emporium</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/1.png" alt="Women-owned brands" />
                            <p>Brands owned by women</p>
                        </div>
                    </div>
                    <a href="#" className="card-link">Explore more</a>
                </div>

                {/* Container 3 */}
                <div className="card">
                    <h2 className="card-title">Style & innovation from Small Businesses</h2>
                    <div className="card-grid">
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/3.png" alt="Home decor" />
                            <p>Up to 55% off | Home decor</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/3.png" alt="Fashion accessories" />
                            <p>Up to 75% off | Fashion accessories</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/2.png" alt="Furniture" />
                            <p>Up to 60% off | Furniture</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/1.png" alt="Electronic accessories" />
                            <p>Up to 70% off | Electronic accessories</p>
                        </div>
                    </div>
                    <a href="#" className="card-link">See all offers</a>
                </div>
                
                {/* Container 4 */}
                <div className="card">
                    <h2 className="card-title">Collections from Emerging Businesses</h2>
                    <div className="card-grid">
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/3.png" alt="Lamps and lights" />
                            <p>Up to 55% off | Lamps & lights</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/1.png" alt="Furnishing" />
                            <p>Up to 70% off | Furnishing</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/2.png" alt="Electronics" />
                            <p>Up to 60% off | Electronics</p>
                        </div>
                        <div className="card-item">
                            <img src="https://testingbucketboto3.s3.amazonaws.com/3.png" alt="Home decor" />
                            <p>Up to 65% off | Home decor</p>
                        </div>
                    </div>
                    <a href="#" className="card-link">See all offers</a>
                </div>
            </div>
        </div>
    );
};

export default CollectionCard;
