

import React, { useState, useEffect } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from './firebaseSetup.js';
import { Button, TextField, Box, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toastError, toastSuccess } from "../../../utils/toastUtil.js";

function UserLogin() {
  const [otp, setOtp] = useState("");
  const [mobile, setMobile] = useState("");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(180); // 3 minutes timer
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (resendDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendDisabled]);

  const handleTimer = () => {
    setTimer(180); // reset to 3 minutes
    setResendDisabled(true);
  };

  const sendOtp = async () => {
    setLoading(true);
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha', {size: 'invisible'});
      }
      // const recaptcha = new RecaptchaVerifier(auth, 'recaptcha', {size:'invisible'});
      const recaptcha = window.recaptchaVerifier;
      console.log("recaptcha in setotp,", recaptcha)
      const confirmation = await signInWithPhoneNumber(auth, `+91${mobile}`, recaptcha);
      if(confirmation?.verificationId){
        console.log("confirmation in recatpcha", confirmation)
        setUser(confirmation);
        handleTimer();
        toastSuccess("OTP sent successfully");
      }
    } catch (error) {
      console.warn("error in sendotp", error?.response?.message);
      console.warn("error in sendotp", error?.error);
      toastError("Failed to send OTP. Try Again.");
      console.error(error);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    setLoading(false);
  };

  const verifyOtp = async () => {
    setLoading(true);
    try {
      const data = await user.confirm(otp);
      console.log('user data', data);

      // Set user data in session and local storage
      sessionStorage.setItem("userSession", JSON.stringify(data));
      sessionStorage.setItem("userId", data?.user?.uid);
      sessionStorage.setItem("userAccessToken", data?.user?.accessToken);
      // localStorage.setItem("userId", data?.user?.uid);

      // Navigate to home after successful login
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const resendOtp = () => {
    sendOtp();
    setResendDisabled(true);
  };

  return (
    <Box
      sx={{
        marginTop: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4" mb={2} fontWeight="bold">Login with OTP</Typography>

      <TextField
        label="Enter Mobile Number"
        variant="outlined"
        type="tel"
        value={mobile}
        onChange={(e) => setMobile(e.target.value)}
        sx={{ mb: 2, width: '300px' }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={sendOtp}
        disabled={loading || !mobile}
        sx={{ mb: 2 }}
      >
        {loading ? <>Send OTP<CircularProgress size={24} /></> : 'Send OTP'}
      </Button>

      <div id="recaptcha"></div>

      {user && (
        <>
          <TextField
            label="Enter OTP"
            variant="outlined"
            type="tel"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            sx={{ mb: 2, width: '300px' }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={verifyOtp}
            disabled={loading || !otp}
            sx={{ mb: 2 }}
          >
            {loading ? <>Verify OTP<CircularProgress size={24} /></> : 'Verify OTP'}
          </Button>

          <Typography variant="body2" sx={{ mb: 2 }}>
            Resend OTP in {timer}s
          </Typography>

          <Button
            variant="outlined"
            color="secondary"
            onClick={resendOtp}
            disabled={resendDisabled || loading}
          >
            Resend OTP
          </Button>
        </>
      )}
    </Box>
  );
}

export default UserLogin;
