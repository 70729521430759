import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toastSuccess } from "../../../../utils/toastUtil";
import UserAddress from "./UserAddress";

const UserCheckout = () => {
  const { cartItems } = useSelector((state) => state.userDetailsData);
  const [paymentMethod, setPaymentMethod] = useState("Razorpay");
  const [isZipCodeServicable, setIsZipCodeServicable] = useState(false);
  const navigate = useNavigate();
  const userLogin = Cookies.get("userLogin");
  const userDetailsFetch = JSON.parse(
    sessionStorage.getItem("userDetailsFetch")
  );

  const totalPrice = cartItems.reduce((total, item) => {
    const itemPrice = item.discount
      ? item.price - (item.price * item.discount) / 100
      : item.price;
    return total + itemPrice * item.quantity;
  }, 0);

  const totalDiscount = cartItems.reduce((total, item) => {
    if (item.discount) {
      const discountAmount = (item.price * item.discount) / 100;
      return total + discountAmount * item.quantity;
    }
    return total;
  }, 0);

  const totalAmountWithoutDiscount = cartItems.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);


  const handlePlaceOrder = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append("amount", `${Math.floor(totalPrice)}`);

      const response = await fetch(
        "http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/initiate-payment/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.status === "success") {
        initiateRazorpay(data.id);
      } else {
        alert("Payment initiation failed.");
      }
    } catch (error) {
      console.error("Error fetching data from the API:", error);
      alert("Error: " + error.message);
    }
  };

  const initiateRazorpay = (orderId) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      const options = {
        key: "rzp_test_2c6T3q0qgPrzXw",
        amount: `${Math.floor(totalPrice)}`,
        currency: "INR",
        name: "Acme Corp",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: orderId,

        handler: async function (response) {
          // Send the payment success data to your server
          // const postData = cartItems;
          const userId = JSON.parse(sessionStorage.getItem("userDetailsFetch"))
          const address = sessionStorage.getItem("billUserAddress")

          const postData = {
            user: userId?.id,
            order_id: orderId,
            address: address,
            delivery_status: "pending",
            payment_status: "successful",
            coupon: {
                name: "",
                amount: "",
            },
            // created_at: new Date().toISOString(),
            region: "po",
            subtotal: totalAmountWithoutDiscount,
            total_price: totalPrice,
            order_items: cartItems
          };

          if (
            response?.razorpay_payment_id &&
            response?.razorpay_order_id &&
            response?.razorpay_signature
          ) {
            try {
              const apiResponse = await fetch(
                "http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com/api/orders/", // Your POST API URL
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(postData),
                }
              );

              if (!apiResponse.ok) {
                throw new Error("Failed to post payment data to the server");
              }

              const result = await apiResponse.json();
              console.log(
                "Server response after posting payment data:",
                result
              );
              toastSuccess("Order Placed Successfully");
              sessionStorage.removeItem("billUserAddress")
              setTimeout(() => {
                navigate("/");
              }, 1000);
            } catch (error) {
              console.error("Error posting payment data:", error);
            }
          }
        },
        callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
        prefill: {
          name: userDetailsFetch?.name || "",
          // email: userDetailsFetch?.email || "",
          contact: userDetailsFetch?.phone || "",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {});
      rzp1.open();
    };
    document.body.appendChild(script);
  };

  return (
    <>
      {sessionStorage.getItem("userToken") ||
      sessionStorage.getItem("userSession") ? (
        <Container maxWidth="lg" className="py-8">
          {!userLogin ? (
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Paper elevation={3} className="p-4 mb-4">
                  <Typography variant="h6" className="font-bold mb-2">
                    Delivery Address
                  </Typography>

                  <UserAddress
                    setIsZipCodeServicable={setIsZipCodeServicable}
                    isZipCodeServicable={isZipCodeServicable}
                  />
                </Paper>

                <Paper elevation={3} className="p-4">
                  <Typography variant="h6" className="font-bold mb-2">
                    Payment Method
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="payment-method"
                      name="payment-method"
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <FormControlLabel
                        value="Razorpay"
                        control={<Radio />}
                        label="Razorpay"
                      />
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>

              {isZipCodeServicable && (
                <Grid item xs={12} md={4}>
                  <Paper elevation={3} className="p-4">
                    <Typography variant="h6" className="font-bold mb-2">
                      Order Summary
                    </Typography>
                    <Box className="flex justify-between my-2">
                      <Typography>Sub Total</Typography>
                      <Typography>
                        Rs. {totalAmountWithoutDiscount?.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box className="flex justify-between my-2">
                      <Typography>Discount</Typography>
                      <Typography>- Rs. {totalDiscount?.toFixed(2)}</Typography>
                    </Box>
                    <Box className="flex justify-between my-2">
                      <Typography>Total Payable</Typography>
                      <Typography>Rs. {totalPrice?.toFixed(2)}</Typography>
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="mt-4"
                      onClick={handlePlaceOrder}
                    >
                      Place Order
                    </Button>
                  </Paper>
                </Grid>
              )}
            </Grid>
          ) : (
            <Typography variant="h6" className="font-bold">
              Please log in to complete the checkout process.
            </Typography>
          )}
        </Container>
      ) : (
        <div
          className="flex flex-col mt-16 items-center h-screen"
          style={{ fontFamily: "Poppins" }}
        >
          <h2 style={{ fontWeight: "bold" }}>Please Login First</h2>
          <Button
            style={{ width: "50%", fontWeight: "bold" }}
            onClick={() => navigate("/login")}
          >
            Go to Login
          </Button>
        </div>
      )}
    </>
  );
};

export default UserCheckout;
