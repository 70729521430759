import { apiConfig } from "./apiConfig";
import axios from "axios";
import { getCookie } from "../../utils";

// axios.defaults.withXSRFToken = true;
// axios.defaults.withCredentials = true;
export const centralizedApi = {
  //   **************************************Auth Starts**************************************
  signupLogin: async (data) => {
    const response = await axios.post(apiConfig.baseUrl + apiConfig.auth, data);
    return response.data;
  },
  //   **************************************Auth Ends**************************************

  //   **************************************Category Starts**************************************
  categories: async () => {
    const csrfToken = getCookie("adminToken");
    const response = await axios.get(apiConfig.baseUrl + apiConfig.categories, 
    //   {
    //   headers: {
    //     "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
    //     Host: "ecom2-env.eba-gmk4bctt.eu-north-1.elasticbeanstalk.com",
    //     cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
    //       "adminSessionId"
    //     )}`,
    //     "Access-Control-Allow-Origin": "*",
    //     "Content-Type": "application/json",
    //   },
    //   withCredentials: true, // Include cookies in the request
    // }
  );
    return response.data;
  },
  createCategory: async (data) => {
    const csrfToken = getCookie("adminToken");
    const response = await axios.post(
      apiConfig.baseUrl + apiConfig.categories,
      data,
      // {
      //   headers: {
      //     "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
      //     cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
      //       "adminSessionId"
      //     )}`,
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      // }
    );
    return response.data;
  },
  editCategory: async (id, data) => {
    console.log("id and data", id, data)
    // const csrfToken = getCookie("adminToken");
    const response = await axios.patch(
      apiConfig.baseUrl + apiConfig.categories + `${id}/`,
        data,
      // {
      //   data,
      //   headers: {
      //     "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
      //     cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
      //       "adminSessionId"
      //     )}`,
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      // }
    );
    return response?.data;
  },
  deleteCategory: async (id) => {
    const response = await axios.delete(
      apiConfig.baseUrl + apiConfig.categories + `${id}/`,
    );
    return response?.data;
  },
  //   **************************************Category Ends**************************************

  //   **************************************SubCategory Starts**************************************
  subCategories: async () => {
    // const csrfToken = getCookie("adminToken");
    const response = await axios.get(
      apiConfig.baseUrl + apiConfig.subcategories,
      // {
      //   headers: {
      //     "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
      //     cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
      //       "adminSessionId"
      //     )}`,
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      //   withCredentials: true, // Include cookies in the request
      // }
    );
    return response.data;
  },
  createSubCategory: async (data) => {
    const csrfToken = getCookie("adminToken");
    const response = await axios.post(
      apiConfig.baseUrl + apiConfig.subcategories,
      data,
      // {
        // headers: {
        //   "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
        //   cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
        //     "adminSessionId"
        //   )}`,
        //   "Access-Control-Allow-Origin": "*",
        //   "Content-Type": "application/json",
        // },
      // }
    );
    return response.data;
  },
  editSubCategory: async (id, data) => {
    // const csrfToken = getCookie("adminToken");
    const response = await axios.put(
      apiConfig.baseUrl + apiConfig.subcategories + `${id}/`,
      data,
      // {
      //   headers: {
      //     "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
      //     cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
      //       "adminSessionId"
      //     )}`,
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      // }
    );
    return response?.data;
  },
  deleteSubCategory: async (id) => {
    const csrfToken = getCookie("adminToken");
    const response = await axios.delete(
      apiConfig.baseUrl + apiConfig.subcategories + `${id}/`,
      // {
      //   headers: {
      //     "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
      //     cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
      //       "adminSessionId"
      //     )}`,
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      // }
    );
    return response?.data;
  },
  //   **************************************SubCategory Ends**************************************

  //   **************************************Product/Item Starts**************************************
  items: async () => {
    const csrfToken = getCookie("adminToken");

    const response = await axios.get(apiConfig.baseUrl + apiConfig.items, 
    //   {
    //   headers: {
    //     "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
    //     cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
    //       "adminSessionId"
    //     )}`,
    //     "Access-Control-Allow-Origin": "*",
    //     "Content-Type": "application/json",
    //   },
    //   withCredentials: true, // Ensure cookies are included in the request
    // }
  );
    return response.data;
  },

  createItem: async (formDataToSend) => {
    console.log("formdata for creating item", formDataToSend)
    const csrfToken = getCookie("adminToken");
    const response = await axios.post(apiConfig.baseUrl + apiConfig.items, 
      formDataToSend,
    //   {
    //   headers: {
    //     "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
    //     cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
    //       "adminSessionId"
    //     )}`,
    //     "Access-Control-Allow-Origin": "*",
    //     "Content-Type": "application/json",
    //   },
    // }
  );
    return response.data;
  },
  editItem: async (id, formDataToSend) => {
    const csrfToken = getCookie("adminToken");
    const response = await axios.put(
      apiConfig.baseUrl + apiConfig.items + `${id}/`,
      formDataToSend
      // {
      //   data,
      //   headers: {
      //     "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
      //     cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
      //       "adminSessionId"
      //     )}`,
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      // }
    );
    return response?.data;
  },
  deleteItem: async (id) => {
    const csrfToken = getCookie("adminToken");
    const response = await axios.delete(
      apiConfig.baseUrl + apiConfig.items + `${id}/`,
      // {
      //   headers: {
      //     "X-CSRFToken": csrfToken, // Make sure to use the actual CSRF token
      //     cookies: `csrftoken=${getCookie("adminToken")};sessionid=${getCookie(
      //       "adminSessionId"
      //     )}`,
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      // }
    );
    return response?.data;
  },
  //   **************************************Product/Item Ends**************************************
};
