

import React, { useState } from "react";
import {
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Slider,
  Box,
  Button,
  Drawer,
} from "@mui/material";
import { styled } from "@mui/system";

const SidebarContainer = styled(Box)({
  width: "250px",
  backgroundColor: "#f4f4f4",
  padding: "1rem",
  borderRight: "1px solid #ddd",
});

const FilterSidebar = () => {
  const [priceRange, setPriceRange] = useState([0, 5000]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };

  return (
    <>
      {/* Button for mobile/tablet view */}
      <Button
        variant="contained"
        onClick={() => toggleDrawer(true)}
        sx={{
          display: { xs: "block", md: "none" },
          position: "relative",
          top: "10px",
          left: "10px",
          width:'max-content'
        }}
      >
        Filters
      </Button>

      {/* Drawer for mobile/tablet view */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        PaperProps={{ style: { width: "250px" } }}
      >
        <SidebarContainer className="space-y-4">
          <Typography variant="h6" gutterBottom>
            Filters
          </Typography>

          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Categories
            </Typography>
            <FormControlLabel control={<Checkbox />} label="Electronics" />
            <FormControlLabel control={<Checkbox />} label="Fashion" />
            <FormControlLabel control={<Checkbox />} label="Home & Furniture" />
            <FormControlLabel control={<Checkbox />} label="Toys" />
          </FormControl>

          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Price Range
            </Typography>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={0}
              max={5000}
              step={100}
            />
            <Box className="flex justify-between text-sm">
              <Typography>₹{priceRange[0]}</Typography>
              <Typography>₹{priceRange[1]}</Typography>
            </Box>
          </FormControl>

          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Ratings
            </Typography>
            <FormControlLabel control={<Checkbox />} label="1 Star & Up" />
            <FormControlLabel control={<Checkbox />} label="2 Stars & Up" />
            <FormControlLabel control={<Checkbox />} label="3 Stars & Up" />
            <FormControlLabel control={<Checkbox />} label="4 Stars & Up" />
            <FormControlLabel control={<Checkbox />} label="5 Stars" />
          </FormControl>

          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Brand
            </Typography>
            <FormControlLabel control={<Checkbox />} label="Brand A" />
            <FormControlLabel control={<Checkbox />} label="Brand B" />
            <FormControlLabel control={<Checkbox />} label="Brand C" />
          </FormControl>

          <Button
            variant="contained"
            onClick={() => toggleDrawer(false)}
            sx={{ width: "100%", mt: 2 }}
          >
            Apply Filters
          </Button>
        </SidebarContainer>
      </Drawer>

      {/* Sidebar for desktop view */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <SidebarContainer className="space-y-4">
          <Typography variant="h6" gutterBottom>
            Filters
          </Typography>

          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Categories
            </Typography>
            <FormControlLabel control={<Checkbox />} label="Electronics" />
            <FormControlLabel control={<Checkbox />} label="Fashion" />
            <FormControlLabel control={<Checkbox />} label="Home & Furniture" />
            <FormControlLabel control={<Checkbox />} label="Toys" />
          </FormControl>

          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Price Range
            </Typography>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={0}
              max={5000}
              step={100}
            />
            <Box className="flex justify-between text-sm">
              <Typography>₹{priceRange[0]}</Typography>
              <Typography>₹{priceRange[1]}</Typography>
            </Box>
          </FormControl>

          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Ratings
            </Typography>
            <FormControlLabel control={<Checkbox />} label="1 Star & Up" />
            <FormControlLabel control={<Checkbox />} label="2 Stars & Up" />
            <FormControlLabel control={<Checkbox />} label="3 Stars & Up" />
            <FormControlLabel control={<Checkbox />} label="4 Stars & Up" />
            <FormControlLabel control={<Checkbox />} label="5 Stars" />
          </FormControl>

          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Brand
            </Typography>
            <FormControlLabel control={<Checkbox />} label="Brand A" />
            <FormControlLabel control={<Checkbox />} label="Brand B" />
            <FormControlLabel control={<Checkbox />} label="Brand C" />
          </FormControl>
        </SidebarContainer>
      </Box>
    </>
  );
};

export default FilterSidebar;
